import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogOut } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import {
  ArrowLeftIcon,
  CheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid"
import { Link } from "gatsby"

const AccountStatusInner = props => {
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication } = useContext(
    AuthenticationContext
  )

  const preLogOut = async () => {
    // let logoutoken = localStorage.getItem("logOutToken")
    let res = await makeLogOut(state.token,authentication)
    if (res.ok) {
      dispatchAuthentication({
        type: "LOGOUT",
      })
      dispatch({ type: "SET_INITIAL" })

      return true
    } else {
      toggleNotification({
        content: "Fail to Log Out",
      })
      return false
    }
  }

  const doLogOut = async () => {
    preLogOut().then(d => {
      if (d) navigate("/login", { state: { logOut: true } })
    })
  }

  return (
    <>
      <div>
        <h1 className="text-strong-20 !text-[16px] !text-left !leading-[1.2] mb-4">
          Your account will need to be approved before you can login.
        </h1>
        <p className="text-sm mb-8">
          You will receive an email from us within 2-3 business days, either
          approving your account or asking you to provide additional information
          as required.
        </p>
        <h1 className="text-strong-20 !text-[16px] !text-left !leading-[1.3] mb-4">
          Why does my account need to be approved before I can login?
        </h1>
        <p className="text-sm mb-4">
          Newly registered users and companies are reviewed to help ensure they
          are setup for success on O2O. In addition, some of the information and
          features that are made available on O2O may have special requirements
          that need to be met by users and companies who want access to them.
        </p>
        <p className="text-sm mb-4">
          If you submitted a registration for a company already on O2O, then the
          user who manages that company on O2O can approve your account. We have
          sent them an email asking for a decision within the next 2-3 business
          days.
        </p>
        <p className="text-sm mb-8">
          If you submitted a new company as part of your registration, one of
          our Account Specialists will review your registration and provide a
          decision within the next 2-3 business days.
        </p>
        <h1 className="text-strong-20 !text-[16px] !text-left !leading-[1.2] mb-4">
          What can I do if I have questions or concerns?
        </h1>
        <p className="text-sm mb-12">
          If you can't find the answers you need in our knowledge centre you can
          submit a service ticket with our{" "}
          <a
            href="https://support.useo2o.com/hc/en-us"
            className="text-red underline"
          >
            support team
          </a>
        </p>
      </div>

      <div className="flex justify-center items-center mt-6">
        <Button red widthAuto onClick={() => doLogOut()}>
          Log Out
        </Button>
      </div>
    </>
  )
}

export default AccountStatusInner
