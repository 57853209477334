/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { Tab } from "@headlessui/react"
import React, { useContext, useEffect, useState } from "react"
import { classNames } from "../../utils"
import { useForm } from "react-hook-form"
import StateContext from "../../context/state/StateContext"
import {
  addMySingleNewForm,
  fileUploadDrupal,
  fileUploadToMediaEntityDrupal,
  getMyListingPost,
  getMySingleEditForm,
  getMySingleForm,
  getSingleStockListing,
  updateMySingleEditForm,
  updateUserStock,
} from "../../helpers/DrupalHelper"
import FloatingInput from "../Elements/Form/FloatingInput"
import Skeleton from "../Skeleton"
import Button from "../Elements/Button"
import ListString from "./DynamicForm/FormTypes/ListString"
// import EntityReferenceRevisions from "./DynamicForm/FormTypes/EntityReferenceRevisions"
import NotificationContext from "../../context/Notification/NotificationContext"
import Media from "./DynamicForm/FormTypes/Media"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import FieldAssets from "./DynamicForm/FormTypes/FieldAssets"
import DataContext from "../../context/Data/DataContext"
import BooleanType from "./DynamicForm/FormTypes/BooleanType"
import mapSingle from "../../utils/mapSingle"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import EntityReferenceRevisionsAsset from "./DynamicForm/FormTypes/EntityReferenceRevisionsAsset"
import EntityReferenceRevisions from "./DynamicForm/FormTypes/EntityReferenceRevisions"
import Allocations from "./CreateAssetAllocations"
import CreateAssetRestrict from "./CreateAssetRestrict"
import CreateAssetInventory from "./CreateAssetInventory"
import FloatingTextarea from "../Elements/Form/FloatingTextarea"
import PriceBooleanType from "./DynamicForm/FormTypes/PriceBooleanType"

const SingleField = props => {
  const { children, small, med } = props

  let maxWidth = "none"
  if (small) maxWidth = 500
  if (med) maxWidth = 700
  return <>{children}</>
}

const CreateAsset = ({
  edit,
  id,
  editCurrentBundle,
  onSave,
  list,
  disableVariantField,
  variantList,
  addInventory,
}) => {
  const { myBrandsAsOptions } = useContext(DataContext).data
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication } = useContext(AuthenticationContext)
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const { companies } = currentUserData
  const [formData, setFormData] = useState(null)
  const [defaultFormData, setDefaultFormData] = useState()
  const [assetGroupFormData, setAssetGroupFormData] = useState()

  console.log({ myBrandsAsOptions, formData })
  const [tabs, setTabs] = useState(
    editCurrentBundle === "asset_group"
      ? [
        {
          name: "General",
          // fields: [
          //   "title",
          //   "field_description",
          //   // "field_asset_company",
          //   "field_asset_brand",
          // ],
          fields: [
            "field_asset_type",
            "field_asset_brand",
            "field_asset_category",
            "field_asset_tags",
          ],
        },
        {
          name: "Variations",
          fields: ["field_assets"],
        },
      ]
      : [
        {
          name: "Overview",
          fields: [
            "title",
            // "field_description",
            // "field_asset_company",
            "field_asset_brand",
            "field_asset_category",
            "field_asset_tags",
          ],
        },
        {
          name: "Description",
          // fields: [
          //   "field_asset_category",
          //   "field_asset_tags",
          //   "field_asset_type",
          // ],
          fields: ["title", "field_description", "field_asset_cover_image"],
        },

        // {
        //   name: "Price",
        //   fields: ["field_asset_price"],
        // },
        {
          name: "Visibility",
          fields: [
            "field_asset_by_request",
            "field_user_groups",
            "field_active",
          ],
        },
        {
          name: "Inventory",
          // fields: ["field_asset_files", "field_asset_cover_image"],
          fields: ["field_warehouse_type", "field_stock"],
        },
      ]
  )
  const [fieldAssets, setFieldAssets] = useState([])
  const [variationTouched, setVariationTouched] = useState(false)
  const [isVariation, setIsVariation] = useState(
    editCurrentBundle === "asset_group" ? true : false
  )
  const [tabsTouched, setTabsTouched] = useState([0])
  const [saving, setSaving] = useState(false)
  const [bundle, setBundle] = useState("default")
  const [stock, setStock] = useState(1)
  const [allocationState, setAllocationState] = useState([])
  const { myUserGroups, universalUserGroups } = useContext(DataContext).data
  const [stocks, setStocks] = useState(null)
  const [thumbImages, setThumbImages] = useState([])
  const [current, setCurrent] = useState(null)
  const [body, setBody] = useState(null)
  const [formErrors, setFormErrors] = useState([])
  const [segmentErrors, setSegmentErrors] = useState(new Set())
  const [retailersAloc, setRetailersAloc] = useState(0)
  const [internalAloc, setInternalAloc] = useState(0)
  const [files, setFiles] = useState([])
  const [thumbnail, setThumbnail] = useState([])

  console.log(thumbImages)
  const warehouseTypes = [
    { label: "O2O Warehouse", value: "o2o" },
    { label: "In-house", value: "in-house" },
    { label: "Third party", value: "third-party" },
  ]
  const warehouseTypeDigital = [
    { label: "Digital Storage", value: "digital-storage" },
  ]

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm()

  console.log(errors, "errors")
  console.log(thumbImages, "thumbImages")
  // console.log(watch().field_stock, "for field_stock")

  const returnGroupType = id => {
    let all = [
      ...universalUserGroups.map(item => {
        let groupType = ""
        if (item.companies.length) groupType = "Retail companies"
        if (item.stores.length) groupType = "Retail stores"
        if (item.regions.length) groupType = "Regional retailers"
        if (item.users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
      ...myUserGroups.map(item => {
        let groupType = ""
        if (item.field_group_companies.length) groupType = "Retail companies"
        if (item.field_group_stores.length) groupType = "Retail stores"
        if (item.field_group_regions.length) groupType = "Regional retailers"
        if (item.field_group_users.length) groupType = "Company users"
        return {
          value: item.id,
          label: item.title,
          type: groupType,
        }
      }),
    ]

    let role = all.filter(f => parseInt(f.value) === parseInt(id))[0]
    return role?.type || null
  }

  const [unAloc, setunAloc] = useState(0)

  console.log(unAloc, allocationState)

  useEffect(() => {
    if (stock === "infinite") return setunAloc(999)
    else if (stock >= 0) {
      let internalaloc = 0
      let retailersaloc = 0
      allocationState.map(v => {
        if (v.roles.length) {
          let type = returnGroupType(v.roles[0])
          if (type === "Company users") internalaloc += parseInt(v.aloc)
          else retailersaloc += parseInt(v.aloc)
        }
      })
      setRetailersAloc(retailersaloc)
      setInternalAloc(internalaloc)

      setunAloc(stock - (internalaloc + retailersaloc))
    }
  }, [allocationState, stock])

  useEffect(() => {
    if (watch().field_asset_type === "digital") {
      setValue("field_stock", "infinite")
      setValue("field_warehouse_type", warehouseTypeDigital[0].value)
    } else {
      if (edit) {
        // setValue("field_stock", formData?.field_stock_o2o?.current_value)
        // if (formData?.field_warehouse_type?.current_value === "in-house") {
        //   setValue(
        //     "field_stock",
        //     parseInt(formData?.field_stock_in_house?.current_value)
        //   )
        // } else if (
        //   formData?.field_warehouse_type?.current_value === "third-party"
        // ) {
        //   setValue(
        //     "field_stock",
        //     parseInt(formData?.field_stock_third_party?.current_value)
        //   )
        // } else if (formData?.field_warehouse_type?.current_value === "o2o") {
        //   setValue(
        //     "field_stock",
        //     parseInt(formData?.field_stock_o2o?.current_value)
        //   )
        // }
      } else {
        setValue("field_stock", 0)
        setValue("field_warehouse_type", warehouseTypes?.[0]?.value)
      }
    }
  }, [watch().field_asset_type])

  useEffect(() => {
    if (watch().field_asset_type === "digital") {
      setStock(999)
    } else {
      setStock(watch().field_stock)
    }
  }, [watch().field_stock])

  useEffect(async () => {
    if (edit) {
      let data = await getMySingleEditForm(
        state.token,
        "asset",
        editCurrentBundle,
        id
      )
      setFormData(data)

      const allocations = await getSingleStockListing(state.token, id)
      setCurrent(allocations)
      setBody(allocations)
      setAllocationState(allocations?.segments || [])
      setStocks(allocations)
      if (editCurrentBundle === "asset_group") {
        // setIsVariation(true)
      }
    } else {
      const init = {
        // id: "37909",
        // sku: "148909",
        // title: "tt",
        // active: 1,
        fulfillment_type: "o2o",
        stock_o2o: 0,
        stock_in_house: 0,
        stock_third_party: 0,
        notif_total: "0",
        notif_rep: "0",
        notif_ret: "0",
        stock_status: "out-of-stock",
        segments: [],

        // brand: {
        //   id: 160,
        //   name: "Demo Canada Brand",
        //   logo: "https://useo2o.s3.ca-central-1.amazonaws.com/d/b/2023-07/demo_brand_0.png?VersionId=uG5rW52YmoUfArOFsfCYsXTfxObLfaff",
        // },
        // company: {
        //   id: 2308,
        //   name: "Demo Canada Brand Company",
        // },
      }
      setBody(init)
      let defaultData = await getMySingleForm(state.token, "asset")
      let assetGroupData = await getMySingleForm(
        state.token,
        "asset",
        "asset_group"
      )
      setDefaultFormData(defaultData)
      setAssetGroupFormData(assetGroupData)
      setFormData(defaultData)
    }
  }, [])
  useEffect(() => {
    if (edit && formData && variationTouched === false) {
      setValue("title", formData?.title?.current_value)

      setValue("field_asset_cost", formData?.field_asset_cost?.current_value)
      setValue("field_vendor", formData?.field_vendor?.current_value)
      setValue("field_height", formData?.field_height?.current_value)
      setValue("field_width", formData?.field_width?.current_value)

      setValue("field_description", formData?.field_description?.current_value)
      setValue(
        "field_asset_brand",
        formData?.field_asset_brand?.current_value.map(item =>
          item.target_id.toString()
        )
      )
      setValue("field_asset_type", formData?.field_asset_type?.current_value)
      setValue(
        "field_active",
        formData?.field_active?.current_value === "0" ? false : true
      )
      setValue(
        "field_asset_category",
        formData?.field_asset_category?.current_value.map(item =>
          item.target_id.toString()
        )
      )
      setValue(
        "field_asset_tags",
        formData?.field_asset_tags?.current_value.map(item =>
          item.target_id.toString()
        )
      )
      setThumbImages(formData?.field_asset_cover_image?.current_value)
      // setValue(
      //   "field_warehouse_type",
      //   formData?.field_warehouse_type?.current_value
      // )
      // if (formData?.field_asset_type?.current_value === "physical") {
      //   console.log(formData?.field_stock_o2o?.current_value)
      //   if (formData?.field_warehouse_type?.current_value === "in-house") {
      //     setValue(
      //       "field_stock",
      //       parseInt(formData?.field_stock_in_house?.current_value)
      //     )
      //   } else if (
      //     formData?.field_warehouse_type?.current_value === "third-party"
      //   ) {
      //     setValue(
      //       "field_stock",
      //       parseInt(formData?.field_stock_third_party?.current_value)
      //     )
      //   } else if (formData?.field_warehouse_type?.current_value === "o2o") {
      //     setValue(
      //       "field_stock",
      //       parseInt(formData?.field_stock_o2o?.current_value)
      //     )
      //   }
      //   setValue("field_asset_cost", formData?.field_asset_cost?.current_value)
      //   setValue("field_vendor", formData?.field_vendor?.current_value)
      //   setValue(
      //     "field_height",
      //     parseFloat(formData?.field_height?.current_value).toString()
      //   )

      //   setValue(
      //     "field_width",
      //     parseFloat(formData?.field_width?.current_value).toString()
      //   )
      // }
      if (
        formData?.field_asset_price?.current_value &&
        formData?.field_asset_price?.current_value !== "0.00"
      ) {
        setValue("field_asset_price_expanded", false)
        setValue(
          "field_asset_price",
          formData?.field_asset_price?.current_value
        )
      }
      if (formData?.field_user_groups?.current_value.length) {
        setValue("restrict_expanded", true)
        setValue(
          "field_user_groups",
          formData?.field_user_groups?.current_value.map(item =>
            item.target_id.toString()
          )
        )
      }
    }
  }, [formData])

  // useEffect(() => {
  //   if (watch().field_warehouse_type === "o2o" && !edit) {
  //     setValue("field_stock", 0)
  //   }
  // }, [watch().field_warehouse_type])

  useEffect(() => {
    if (formData) {
      register("field_description", { required: "This field is required" })
      if (isVariation === false) {
        !edit && setValue("field_asset_type", "digital")
        register("field_asset_brand", { required: "This field is required" })
        register("field_asset_category", { required: "This field is required" })
        register("field_asset_tags")
        register("title", { required: "This field is required" })
        // register("field_warehouse_type", { required: "This field is required" })
        // register("field_stock", { required: "This field is required" })
        // register("fromUpload_field_asset_cover_image", {
        //   required: "This field is required",
        // })
      }
    }
  }, [formData])
  console.log(watch(), allocationState)
  useEffect(() => {
    const hasRequiredType = Object.values(errors).some(
      field => field.type === "required"
    )
    if (hasRequiredType.length) {
      toggleNotification({
        content: `Please select all required fields`,
      })
    }
  }, [errors])
  console.log(body)
  const onSubmit = async v => {
    const inventoryBody = body
    if (formErrors.length !== 0 && segmentErrors.size !== 0) {
      toggleNotification({
        content: "Please complete all fields.",
        error: true,
      })
      return
    } else {
      setSaving(true)
      let data = { ...getValues() }
      if (edit) {
        if (thumbImages?.length === 0 || thumbImages === null) {
          toggleNotification({
            content: `Please select a thumbnail image`,
          })
          setSaving(false)
          return
        }
      } else {
        if (
          isVariation === false &&
          data.fromUpload_field_asset_cover_image?.length === 0) {
          toggleNotification({
            content: `Please select a thumbnail image`,
          })
          setSaving(false)
          return
        }
      }

      // console.log({ ...data, allocationState })
      for (let key in data) {
        if (data[key] === undefined) data[key] = null
      }
      await mapSingle(Object.keys(data), async v => {
        if (v.includes("fromUpload_")) {
          let name = v.replace("fromUpload_", "")
          let files = data[v]
          if (files.length) {
            let ids = []
            await mapSingle(
              files,
              async (file, k) => {
                let res = await fileUploadDrupal(state.token, file)
                if (res.response.ok) {
                  let res2 = await fileUploadToMediaEntityDrupal(
                    state.token,
                    "asset_file",
                    companies[0].id,
                    res.data
                  )
                  toggleNotification({ content: `${file.name} Uploaded` })
                  let rees2Data = await res2.json()
                  ids.push(rees2Data.mid[0].value)
                  delete data[v]
                } else
                  toggleNotification({
                    content: `Fail to Upload ${file.name} `,
                  })
              },
              1
            )
            data[name] = ids
          }
        }
      })
      if (data.fromUpload_field_asset_cover_image) {
        delete data.fromUpload_field_asset_cover_image
      }
      if (data.fromUpload_field_asset_files) {
        delete data.fromUpload_field_asset_files
      }

      if (edit) {
        if (data.field_asset_cover_image === null) {
          delete data.field_asset_cover_image
        }
        console.log({ editCurrentBundle })
        let withOutTitle =
          myBrandsAsOptions.length === 1
            ? {
              ...data,
              field_asset_brand: myBrandsAsOptions[0].value,
            }
            : { ...data }

        let body = {
          json: { ...withOutTitle },
        }
        if (body.json.fromUpload_field_asset_cover_image) {
          delete body.json.fromUpload_field_asset_cover_image
        }
        if (body.json.fromUpload_field_asset_files) {
          delete body.json.fromUpload_field_asset_files
        }
        // if (data.field_warehouse_type === "in-house") {
        //   body.json.field_stock_in_house = data.field_stock
        // } else if (data.field_warehouse_type === "third-party") {
        //   body.json.field_stock_third_party = data.field_stock
        // } else {
        //   body.json.field_stock_o2o = data.field_stock
        // }
        if (withOutTitle.field_asset_type === "digital") {
          delete body.json.field_stock
        }
        let res = await updateMySingleEditForm(
          state.token,
          "asset",
          editCurrentBundle,
          id,
          body,
          authentication)
        if (res.status === 200) {
          if (id) {
            if (data.field_asset_type === "digital") {
              inventoryBody.fulfillment_type = "digital-storage"
              inventoryBody.segments = allocationState
              inventoryBody.stock_status = "in-stock"
              await updateUserStock(state.token, id, inventoryBody)
            }
            // const inventoryData = {
            //   segments: allocationState,
            //   // stock_o2o: data.field_stock,
            //   sku: stocks?.sku,
            //   stock_status: stocks?.stock_status,
            //   active: data.field_active === true ? 1 : 0,
            //   fulfillment_type: data.field_warehouse_type,
            //   id: id,
            //   title: data.title,
            // }
            // if (data.field_warehouse_type === "in-house") {
            //   inventoryData.stock_in_house = data.field_stock
            // } else if (data.field_warehouse_type === "third-party") {
            //   inventoryData.stock_third_party = data.field_stock
            // } else {
            //   inventoryData.stock_o2o = data.field_stock
            // }
            else {
              let res = await updateUserStock(state.token, id, inventoryBody)
              console.log(res)
            }
          }
          console.log(list, { ...data, id })
          if (variantList) {
            const n_data = { ...data, id }
            let objIndex = list.findIndex(obj => obj.id == id)
            let newList = list
            newList[objIndex] = n_data
            let nList = [...newList]

            onSave(nList, "edited")
          } else {
            let objIndex = list.findIndex(obj => obj.id == id)
            let newList = list
            newList[objIndex] = { ...newList[objIndex] }
            let nList = [...newList]

            onSave(nList, "edited")
          }
        } else {
          onSave("Editing asset failed")
        }
      } else {
        let title = data.title
        let withOutTitle =
          myBrandsAsOptions.length === 1
            ? {
              ...data,
              field_asset_brand: myBrandsAsOptions[0].value,
            }
            : { ...data }

        delete withOutTitle.title
        let body = {
          entity_title: title,
          json: { ...withOutTitle },
        }
        if (body.json.fromUpload_field_asset_cover_image) {
          delete body.json.fromUpload_field_asset_cover_image
        }
        if (body.json.fromUpload_field_asset_files) {
          delete body.json.fromUpload_field_asset_files
        }
        // if (data.field_warehouse_type === "in-house") {
        //   body.json.field_stock_in_house = data.field_stock
        // } else if (data.field_warehouse_type === "third-party") {
        //   body.json.field_stock_third_party = data.field_stock
        // } else {
        //   body.json.field_stock_o2o = data.field_stock
        // }
        if (withOutTitle.field_asset_type === "digital") {
          delete body.json.field_stock
        }
        let res = await addMySingleNewForm(state.token, "asset", bundle, body,authentication)

        // const inventoryData = {
        //   active:res.
        // }
        if (res.status === 200) {
          let resData = await res.json
          const allData = await getMyListingPost(
            state.token,
            "asset",
            { title: "", brand: null, status: null },
            "all"
          )
          const newAsset = allData.data.find(
            ({ id }) => id === resData.id?.[0].value?.toString()
          )
          console.log(newAsset)
          if (newAsset) {
            if (newAsset.field_asset_type === "digital") {
              inventoryBody.fulfillment_type = "digital-storage"
              inventoryBody.segments = allocationState
              inventoryBody.stock_status = "in-stock"
              await updateUserStock(state.token, newAsset.id, inventoryBody)
            }
            // let inventoryData = {
            //   ...inventoryBody,
            //   id: newAsset.id,
            //   sku: newAsset.sku,
            //   title: newAsset.title,
            //   active: newAsset.active,
            //   brand: newAsset.field_asset_brand,
            //   company: newAsset.field_asset_company,
            // }
            // if (data.field_warehouse_type === "in-house") {
            //   inventoryData.stock_in_house = data.field_stock
            // } else if (data.field_warehouse_type === "third-party") {
            //   inventoryData.stock_third_party = data.field_stock
            // } else {
            //   inventoryData.stock_o2o = data.field_stock
            // }
            else {
              let res = await updateUserStock(
                state.token,
                newAsset.id,
                inventoryBody
              )
              console.log(res)
            }
          }

          onSave(data, "added", resData)
        } else {
          onSave("fail")
        }
      }

      setSaving(false)
    }
  }
  return (
    <div>
      {formData ? (
        <form
          onSubmit={handleSubmit(
            state.locformsubmit !== null
              ? state.locformsubmit(onSubmit)
              : onSubmit
          )}
        >
          <Tab.Group
            defaultIndex={0}
            onChange={v => {
              if (!tabsTouched.includes(v)) setTabsTouched([...tabsTouched, v])
            }}
          >
            <Tab.List className="hidden md:flex -mb-px space-x-8 border-b border-[#EBEBEB]">
              {tabs.map((item, k) => (
                <Tab
                  key={k}
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? "border-[#222] text-[#222]"
                        : "border-transparent text-[#222] hover:text-gray-500",
                      "whitespace-nowrap  pb-[28px] px-1 border-b-2 text-med-16"
                    )
                  }
                >
                  {Object.keys(errors) &&
                    item.fields.some(item =>
                      Object.keys(errors).includes(item)
                    ) ? (
                    <span className="text-red-600">{item.name}*</span>
                  ) : (
                    item.name
                  )}
                </Tab>
              ))}
            </Tab.List>
            {
              // <Tab.Panel className="pt-[30px]">
              //   <div
              //     className={
              //       "block py-[16px] mb-[20px] px-[18px] w-full bg-transparent border rounded-[8px]  appearance-none  focus:outline-none focus:ring-0  peer} border-[#EBEBEB] focus:border-[#222] focus:text-[#222]"
              //     }
              //   >
              //     <FloatingInput
              //       label={formData.title.label}
              //       className="mb-[20px]"
              //       name={"title"}
              //       error={errors["title"]}
              //       hookForm
              //       //   data={field[1]}
              //       register={register}
              //       setValue={setValue}
              //       watch={watch}
              //       required={true}
              //     />
              //     {/* {myBrandsAsOptions.length > 1 && ( */}
              //     <SingleField small>
              //       <EntityReferenceRevisions
              //         data={formData.field_asset_brand}
              //         errors={errors["field_asset_brand"]}
              //         key={"field_asset_brand"}
              //         name={"field_asset_brand"}
              //         register={register}
              //         label={formData?.field_asset_brand?.label + "*"}
              //         // setValue={setValue}
              //         watch={watch}
              //         token={state.token}
              //         control={control}
              //       />
              //     </SingleField>
              //     {/* )} */}
              //     <FloatingTextarea
              //       label={formData.field_description.label + "*"}
              //       error={errors["field_description"]}
              //       name={"field_description"}
              //       hookForm
              //       data={formData.field_description}
              //       register={register}
              //     />
              //     {!disableVariantField && (
              //       <>
              //         <div className="flex items-center">
              //           <input
              //             type="checkbox"
              //             checked={isVariation}
              //             className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
              //             onClick={() => setVariationTouched(true)}
              //             onChange={async e => {
              //               if (e.target.checked) {
              //                 setBundle("asset_group")
              //                 setIsVariation(true)
              //                 setTabs([
              //                   {
              //                     name: "General",
              //                     fields: [
              //                       "title",
              //                       "field_description",
              //                       // "field_asset_company",
              //                       "field_asset_brand",
              //                     ],
              //                   },
              //                   {
              //                     name: "Variations",
              //                     fields: ["field_assets"],
              //                   },
              //                 ])
              //                 setFormData(assetGroupFormData)
              //                 unregister("field_asset_category")
              //                 unregister("field_asset_tags")
              //               } else {
              //                 setBundle("default")
              //                 setIsVariation(false)
              //                 setTabs([
              //                   {
              //                     name: "General",
              //                     fields: [
              //                       "title",
              //                       "field_description",
              //                       // "field_asset_company",
              //                       "field_asset_brand",
              //                     ],
              //                   },
              //                   {
              //                     name: "Categorization",
              //                     fields: [
              //                       "field_asset_category",
              //                       "field_asset_tags",
              //                       "field_asset_type",
              //                     ],
              //                   },
              //                   {
              //                     name: "Media",
              //                     fields: [
              //                       "field_asset_files",
              //                       "field_asset_cover_image",
              //                     ],
              //                   },
              //                   {
              //                     name: "Price",
              //                     fields: ["field_asset_price"],
              //                   },
              //                   {
              //                     name: "Restrictions",
              //                     fields: [
              //                       "field_asset_by_request",
              //                       "field_user_groups",
              //                       "field_active",
              //                     ],
              //                   },
              //                 ])
              //                 setFormData(defaultFormData)
              //               }
              //             }}
              //             disabled={edit}
              //           ></input>
              //           <label
              //             htmlFor="toggle_variations"
              //             className="ml-3 text-[#222] flex flex-col"
              //           >
              //             This asset has multiple variations
              //           </label>
              //         </div>
              //         <div
              //           css={css`
              //             font-family: "Jost";
              //             font-style: normal;
              //             font-weight: 400;
              //             font-size: 12px;
              //             line-height: 16px;
              //             display: inline-block;
              //             padding: 0 10px;
              //             margin: 0 0 0 18px;
              //             letter-spacing: 0.02em;
              //             color: #717171;
              //           `}
              //         >
              //           Is this asset comprised of multiple variants, such as size
              //           or color?
              //         </div>
              //       </>
              //     )}
              //   </div>
              // </Tab.Panel>
            }
            <Tab.Panel className="pt-[30px]">
              <div
                className={
                  "block py-[16px] mb-[20px] w-full bg-transparent appearance-none  focus:outline-none focus:ring-0  peer} focus:text-[#222]"
                }
              >
                {
                  //   <FloatingInput
                  //   label={formData.title.label}
                  //   className="mb-[20px]"
                  //   name={"title"}
                  //   error={errors["title"]}
                  //   hookForm
                  //   //   data={field[1]}
                  //   register={register}
                  //   setValue={setValue}
                  //   watch={watch}
                  //   required={true}
                  // />
                }

                {!isVariation &&
                  <SingleField small>
                    <ListString
                      data={formData.field_asset_type}
                      errors={errors}
                      key={"field_asset_type"}
                      name={"field_asset_type"}
                      register={register}
                      label={formData?.field_asset_type?.label}
                      // setValue={setValue}
                      watch={watch}
                      control={control}
                      isDisabled={formData.field_asset_type?.current_value}
                    />
                  </SingleField>
                }

                <SingleField small>
                  <EntityReferenceRevisions
                    data={formData.field_asset_brand}
                    errors={errors["field_asset_brand"]}
                    key={"field_asset_brand"}
                    name={"field_asset_brand"}
                    register={register}
                    label={formData?.field_asset_brand?.label + "*"}
                    // setValue={setValue}
                    watch={watch}
                    token={state.token}
                    control={control}
                    placeholder="Select or type to search …"
                  />
                </SingleField>

                {!isVariation &&
                  <SingleField small>
                    <EntityReferenceRevisions
                      data={formData.field_asset_category}
                      errors={errors["field_asset_category"]}
                      key={"field_asset_category"}
                      name={"field_asset_category"}
                      register={register}
                      label={formData?.field_asset_category?.label + "*"}
                      // setValue={setValue}
                      watch={watch}
                      token={state.token}
                      control={control}
                      placeholder="Select or type to search …"
                    />
                  </SingleField>
                }

                {!isVariation &&

                  <SingleField small>
                    <EntityReferenceRevisionsAsset
                      data={{
                        ...formData.field_asset_tags,
                        required: false,
                      }}
                      errors={errors["field_asset_tags"]}
                      key={"field_asset_tags"}
                      name={"field_asset_tags"}
                      register={register}
                      label={formData?.field_asset_tags?.label}
                      // setValue={setValue}
                      watch={watch}
                      token={state.token}
                      control={control}
                      placeholder="Select or type to search …"
                    />
                  </SingleField>
                }

                {!isVariation && watch().field_asset_type === "digital" && (
                  <SingleField med>
                    <Media
                      data={formData.field_asset_files}
                      errors={errors}
                      key={"field_asset_files"}
                      name={"field_asset_files"}
                      register={register}
                      label={"Downloadable File(s)"}
                      description={`This is the file you want people to download as this digital asset. You can upload one document (txt rtf
                        doc docx pdf odp ppt pptx indd xps potx pot), spreadsheet (xls xlsx csv tsv dat ods json), image (jpg jpeg
                        png webp gif tiff tif bmp psd eps ai) or video (mp4 mov wmv avi).`}
                      setValue={setValue}
                      watch={watch}
                      file_size={26214400}
                      noIcon
                      multiFiles={true}
                    />
                  </SingleField>
                )}

                {isVariation &&
                  <>

                    {
                      //  <SingleField small>
                      //   <ListString
                      //     data={formData.field_asset_type}
                      //     errors={errors}
                      //     key={"field_asset_type"}
                      //     name={"field_asset_type"}
                      //     register={register}
                      //     label={formData?.field_asset_type?.label}
                      //     // setValue={setValue}
                      //     watch={watch}
                      //     control={control}
                      //     isDisabled={formData.field_asset_type?.current_value}
                      //   />
                      // </SingleField>
                    }
                    <span
                      // className="text-sm"
                      css={css`
                  font-family: "Jost";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  display: inline-block;
                  padding: 0 10px;
                  margin: 0px 0 15px;
                  letter-spacing: 0.02em;
                  color: #717171;
                `}
                    >
                      The title should include descriptive keywords such as Brand,
                      Related Product, Category and any important Asset Tags.{" "}
                      <span className="italic underline">Important</span> : If your
                      asset is bundled in a specific quantity, then describe that in
                      the title as well to clearly indicate how many a user will be
                      receiving. (e.g. BlazeCo : Ghost Haze Vapes Strain Card -
                      Bundles of 25)
                    </span>
                    <FloatingInput
                      label={formData.title.label}
                      className="mb-[20px]"
                      name={"title"}
                      error={errors["title"]}
                      hookForm
                      //   data={field[1]}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      required={true}
                    />
                    <span
                      // className="text-sm"
                      css={css`
                  font-family: "Jost";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  display: inline-block;
                  padding: 0 10px;
                  margin: 0px 0 15px;
                  letter-spacing: 0.02em;
                  color: #717171;
                `}
                    >
                      The description should provide helpful information about the
                      asset, which may or may not be obvious from the image or
                      title. (e.g. bundled, materials, dimensions, colors, language,
                      exclusions, etc.)
                    </span>
                    <FloatingTextarea
                      label={formData.field_description.label + "*"}
                      className="mb-[20px]"
                      name={"field_description"}
                      error={errors["field_description"]}
                      hookForm
                      data={formData.field_description}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      required={true}
                    />
                    {!isVariation &&
                      <SingleField small>
                        <PriceBooleanType
                          label={formData?.field_asset_price?.label}
                          description={formData?.field_asset_price?.description}
                          className="mb-[20px]"
                          name={"field_asset_price"}
                          error={errors["field_asset_price"]}
                          hookForm
                          control={control}
                          data={formData.field_asset_price}
                          register={register}
                          setValue={setValue}
                          watch={watch}
                          tabsTouched={tabsTouched}
                        />
                      </SingleField>
                    }

                    {!isVariation &&
                      <SingleField med>
                        <Media
                          data={{
                            ...formData.field_asset_cover_image,
                            // required: true,
                          }}
                          errors={errors}
                          key={"field_asset_cover_image"}
                          name={"field_asset_cover_image"}
                          register={register}
                          label={"Thumbnail Image(s)*"}
                          description={`The first image uploaded will be displayed on asset pages as the thumbnail. The first image should be in
                    PNG, JPG or WEBP format. We recommend a square resolution of 500 x 500 pixels. File sizes must be less than 10MB. You can upload additional images to be
                    displayed in asset detail pages.
                    `}
                          setValue={setValue}
                          watch={watch}
                          file_size={10485760}
                          noIcon
                          fileLimit={1}
                          preview
                          setThumbImages={setThumbImages}
                        />
                      </SingleField>
                    }
                    {watch().field_asset_type === "physical" && (
                      <>
                        <span
                          // className="text-sm"
                          css={css`
                      font-family: "Jost";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 20px;
                      display: inline-block;
                      padding: 0 10px;
                      margin: 0px 0 15px;
                      letter-spacing: 0.02em;
                      color: #717171;
                    `}
                        >
                          Keep track of production cost for this asset, which vendor
                          produced it and it’s approximate dimensions.
                        </span>
                        <div className="flex gap-5 w-full justify-center items-center">
                          <span className="w-2/3 gap-5 flex">
                            <FloatingInput
                              label={"Production Cost"}
                              className="mb-[20px]"
                              name={"field_asset_cost"}
                              defaultValue={0}
                              flush={true}
                              error={errors["field_asset_cost"]}
                              hookForm
                              type="number" // Set input type to number
                              //   data={field[1]}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                            />
                            <FloatingInput
                              label={"Vendor Name"}
                              className="mb-[20px]"
                              name={"field_vendor"}
                              error={errors["field_vendor"]}
                              hookForm
                              //   data={field[1]}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                            />
                          </span>
                          <div className="w-1/3 gap-5 flex justify-center items-center">
                            <div className="mb-[20px]">Dimensions</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "fit-content",
                              }}
                            >
                              <FloatingInput
                                label={"W"}
                                className="mb-[20px]"
                                name={"field_width"}
                                error={errors["field_width"]}
                                hookForm
                                type="number"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  padding: "12px",
                                }}
                                //   data={field[1]}
                                register={register}
                                setValue={setValue}
                                watch={watch}
                              />
                            </div>
                            <div className="mb-[20px]">X</div>
                            <div className="">
                              <FloatingInput
                                label={"H"}
                                className="mb-[20px] "
                                name={"field_height"}
                                error={errors["field_height"]}
                                hookForm
                                type="number"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  padding: "12px",
                                }}
                                //   data={field[1]}
                                register={register}
                                setValue={setValue}
                                watch={watch}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  </>

                }

                {/* <FloatingTextarea
                label={formData.field_description.label + "*"}
                error={errors["field_description"]}
                name={"field_description"}
                hookForm
                data={formData.field_description}
                register={register}
              /> */}
                {
                  //   !disableVariantField && (
                  //   <>
                  //     <div className="flex items-center">
                  //       <input
                  //         type="checkbox"
                  //         checked={isVariation}
                  //         className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                  //         onClick={() => setVariationTouched(true)}
                  //         onChange={async e => {
                  //           if (e.target.checked) {
                  //             setBundle("asset_group")
                  //             setIsVariation(true)
                  //             setTabs([
                  //               {
                  //                 name: "General",
                  //                 fields: [
                  //                   // "title",
                  //                   // "field_description",
                  //                   // "field_asset_company",
                  //                   "field_asset_type",
                  //                   "field_asset_brand",
                  //                   "field_asset_category",
                  //                   "field_asset_tags",
                  //                 ],
                  //               },
                  //               {
                  //                 name: "Variations",
                  //                 fields: ["field_assets"],
                  //               },
                  //             ])
                  //             setFormData(assetGroupFormData)
                  //             unregister("field_asset_category")
                  //             unregister("field_asset_tags")
                  //           } else {
                  //             setBundle("default")
                  //             setIsVariation(false)
                  //             setTabs([
                  //               {
                  //                 name: "General",
                  //                 fields: [
                  //                   // "title",
                  //                   // "field_description",
                  //                   // "field_asset_company",
                  //                   "field_asset_type",
                  //                   "field_asset_brand",
                  //                   "field_asset_category",
                  //                   "field_asset_tags",
                  //                 ],
                  //               },
                  //               {
                  //                 name: "Categorization",
                  //                 fields: [
                  //                   "title",
                  //                   "field_description",
                  //                   // "field_asset_category",
                  //                   // "field_asset_tags",
                  //                   // "field_asset_type",
                  //                 ],
                  //               },
                  //               {
                  //                 name: "Media",
                  //                 fields: [
                  //                   "field_asset_files",
                  //                   "field_asset_cover_image",
                  //                 ],
                  //               },
                  //               {
                  //                 name: "Price",
                  //                 fields: ["field_asset_price"],
                  //               },
                  //               {
                  //                 name: "Restrictions",
                  //                 fields: [
                  //                   "field_asset_by_request",
                  //                   "field_user_groups",
                  //                   "field_active",
                  //                 ],
                  //               },
                  //             ])
                  //             setFormData(defaultFormData)
                  //           }
                  //         }}
                  //         disabled={edit}
                  //       ></input>
                  //       <label
                  //         htmlFor="toggle_variations"
                  //         className="ml-3 text-[#222] flex flex-col"
                  //       >
                  //         This asset has multiple variations
                  //       </label>
                  //     </div>
                  //     <div
                  //       css={css`
                  //         font-family: "Jost";
                  //         font-style: normal;
                  //         font-weight: 400;
                  //         font-size: 12px;
                  //         line-height: 16px;
                  //         display: inline-block;
                  //         padding: 0 10px;
                  //         margin: 0 0 0 18px;
                  //         letter-spacing: 0.02em;
                  //         color: #717171;
                  //       `}
                  //     >
                  //       Is this asset comprised of multiple variants, such as size
                  //       or color?
                  //     </div>
                  //   </>
                  // )
                }

                {!disableVariantField && (
                  <>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={isVariation}
                        className="h-4 w-4 border-[#EBEBEB] rounded text-[#222] focus:ring-1 focus:ring-offset-2 focus:ring-[#222]"
                        onClick={() => setVariationTouched(true)}
                        onChange={async e => {
                          if (e.target.checked) {
                            setBundle("asset_group")
                            setIsVariation(true)
                            setTabs([
                              {
                                name: "General",
                                // fields: [
                                //   "title",
                                //   "field_description",
                                //   // "field_asset_company",
                                //   "field_asset_brand",
                                // ],
                                fields: [
                                  "field_asset_type",
                                  "field_asset_brand",
                                  "field_asset_category",
                                  "field_asset_tags",
                                ],
                              },
                              {
                                name: "Variations",
                                fields: ["field_assets"],
                              }
                            ])
                            setFormData(assetGroupFormData)
                            unregister("field_asset_category")
                            unregister("field_asset_tags")
                          } else {
                            setBundle("default")
                            setIsVariation(false)
                            setTabs([
                              {
                                name: "Overview",
                                fields: [
                                  "title",
                                  // "field_description",
                                  // "field_asset_company",
                                  "field_asset_brand",
                                  "field_asset_category",
                                  "field_asset_tags",
                                ],
                              },
                              {
                                name: "Description",
                                // fields: [
                                //   "field_asset_category",
                                //   "field_asset_tags",
                                //   "field_asset_type",
                                // ],
                                fields: ["title", "field_description", "field_asset_cover_image"],
                              },
                              {
                                name: "Inventory",
                                // fields: ["field_asset_files", "field_asset_cover_image"],
                                fields: ["field_warehouse_type", "field_stock"],
                              },
                              // {
                              //   name: "Price",
                              //   fields: ["field_asset_price"],
                              // },
                              {
                                name: "Visibility",
                                fields: [
                                  "field_asset_by_request",
                                  "field_user_groups",
                                  "field_active",
                                ],
                              },
                            ])
                            setFormData(defaultFormData)
                          }
                        }}
                        disabled={edit}
                      ></input>
                      <label
                        htmlFor="toggle_variations"
                        className="ml-3 text-[#222] flex flex-col"
                      >
                        This asset has multiple variations
                      </label>
                    </div>
                    <div
                      css={css`
                          font-family: "Jost";
                          font-style: normal;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 16px;
                          display: inline-block;
                          padding: 0 10px;
                          margin: 0 0 0 18px;
                          letter-spacing: 0.02em;
                          color: #717171;
                        `}
                    >
                      Is this asset comprised of multiple variants, such as size
                      or color?
                    </div>
                  </>
                )}
              </div>
            </Tab.Panel>
            <>
              {!isVariation &&
                <Tab.Panel className="pt-[30px]">
                  {
                    //  <SingleField small>
                    //   <ListString
                    //     data={formData.field_asset_type}
                    //     errors={errors}
                    //     key={"field_asset_type"}
                    //     name={"field_asset_type"}
                    //     register={register}
                    //     label={formData?.field_asset_type?.label}
                    //     // setValue={setValue}
                    //     watch={watch}
                    //     control={control}
                    //     isDisabled={formData.field_asset_type?.current_value}
                    //   />
                    // </SingleField>
                  }
                  <span
                    // className="text-sm"
                    css={css`
                    font-family: "Jost";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    display: inline-block;
                    padding: 0 10px;
                    margin: 0px 0 15px;
                    letter-spacing: 0.02em;
                    color: #717171;
                  `}
                  >
                    The title should include descriptive keywords such as Brand,
                    Related Product, Category and any important Asset Tags.{" "}
                    <span className="italic underline">Important</span> : If your
                    asset is bundled in a specific quantity, then describe that in
                    the title as well to clearly indicate how many a user will be
                    receiving. (e.g. BlazeCo : Ghost Haze Vapes Strain Card -
                    Bundles of 25)
                  </span>
                  <FloatingInput
                    label={formData.title.label}
                    className="mb-[20px]"
                    name={"title"}
                    error={errors["title"]}
                    hookForm
                    //   data={field[1]}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    required={true}
                  />
                  <span
                    // className="text-sm"
                    css={css`
                    font-family: "Jost";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 20px;
                    display: inline-block;
                    padding: 0 10px;
                    margin: 0px 0 15px;
                    letter-spacing: 0.02em;
                    color: #717171;
                  `}
                  >
                    The description should provide helpful information about the
                    asset, which may or may not be obvious from the image or
                    title. (e.g. bundled, materials, dimensions, colors, language,
                    exclusions, etc.)
                  </span>
                  <FloatingTextarea
                    label={formData.field_description.label + "*"}
                    className="mb-[20px]"
                    name={"field_description"}
                    error={errors["field_description"]}
                    hookForm
                    data={formData.field_description}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    required={true}
                  />
                  <SingleField small>
                    <PriceBooleanType
                      label={formData?.field_asset_price?.label}
                      description={formData?.field_asset_price?.description}
                      className="mb-[20px]"
                      name={"field_asset_price"}
                      error={errors["field_asset_price"]}
                      hookForm
                      control={control}
                      data={formData.field_asset_price}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      tabsTouched={tabsTouched}
                    />
                  </SingleField>
                  <SingleField med>
                    <Media
                      data={{
                        ...formData.field_asset_cover_image,
                        // required: true,
                      }}
                      errors={errors}
                      key={"field_asset_cover_image"}
                      name={"field_asset_cover_image"}
                      register={register}
                      label={"Thumbnail Image(s)*"}
                      description={`The first image uploaded will be displayed on asset pages as the thumbnail. The first image should be in
                      PNG, JPG or WEBP format. We recommend a square resolution of 500 x 500 pixels. File sizes must be less than 10MB. You can upload additional images to be
                      displayed in asset detail pages.
                      `}
                      setValue={setValue}
                      watch={watch}
                      file_size={10485760}
                      noIcon
                      fileLimit={1}
                      preview
                      setThumbImages={setThumbImages}
                    />
                  </SingleField>
                  {watch().field_asset_type === "physical" && (
                    <>
                      <span
                        // className="text-sm"
                        css={css`
                        font-family: "Jost";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 20px;
                        display: inline-block;
                        padding: 0 10px;
                        margin: 0px 0 15px;
                        letter-spacing: 0.02em;
                        color: #717171;
                      `}
                      >
                        Keep track of production cost for this asset, which vendor
                        produced it and it’s approximate dimensions.
                      </span>
                      <div className="flex gap-5 w-full justify-center items-center">
                        <span className="w-2/3 gap-5 flex">
                          <FloatingInput
                            label={"Production Cost"}
                            className="mb-[20px]"
                            name={"field_asset_cost"}
                            defaultValue={0}
                            flush={true}
                            error={errors["field_asset_cost"]}
                            hookForm
                            type="number" // Set input type to number
                            //   data={field[1]}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                          />
                          <FloatingInput
                            label={"Vendor Name"}
                            className="mb-[20px]"
                            name={"field_vendor"}
                            error={errors["field_vendor"]}
                            hookForm
                            //   data={field[1]}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                          />
                        </span>
                        <div className="w-1/3 gap-5 flex justify-center items-center">
                          <div className="mb-[20px]">Dimensions</div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "fit-content",
                            }}
                          >
                            <FloatingInput
                              label={"W"}
                              className="mb-[20px]"
                              name={"field_width"}
                              error={errors["field_width"]}
                              hookForm
                              type="number"
                              style={{
                                width: "60px",
                                height: "60px",
                                padding: "12px",
                              }}
                              //   data={field[1]}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                            />
                          </div>
                          <div className="mb-[20px]">X</div>
                          <div className="">
                            <FloatingInput
                              label={"H"}
                              className="mb-[20px] "
                              name={"field_height"}
                              error={errors["field_height"]}
                              hookForm
                              type="number"
                              style={{
                                width: "60px",
                                height: "60px",
                                padding: "12px",
                              }}
                              //   data={field[1]}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {
                    //   <SingleField small>
                    //   <EntityReferenceRevisionsAsset
                    //     data={formData.field_asset_category}
                    //     errors={errors["field_asset_category"]}
                    //     key={"field_asset_category"}
                    //     name={"field_asset_category"}
                    //     register={register}
                    //     label={formData?.field_asset_category?.label}
                    //     // setValue={setValue}
                    //     watch={watch}
                    //     token={state.token}
                    //     control={control}
                    //   />
                    // </SingleField>
                  }
                  {
                    //   <SingleField small>
                    //   <EntityReferenceRevisionsAsset
                    //     data={formData.field_asset_tags}
                    //     errors={errors["field_asset_tags"]}
                    //     key={"field_asset_tags"}
                    //     name={"field_asset_tags"}
                    //     register={register}
                    //     label={formData?.field_asset_tags?.label}
                    //     // setValue={setValue}
                    //     watch={watch}
                    //     token={state.token}
                    //     control={control}
                    //   />
                    // </SingleField>
                  }
                </Tab.Panel>
              }

              {
                //   <Tab.Panel className="pt-[30px]">
                //   <SingleField small>
                //     <PriceBooleanType
                //       label={formData?.field_asset_price?.label}
                //       description={formData?.field_asset_price?.description}
                //       className="mb-[20px]"
                //       name={"field_asset_price"}
                //       error={errors["field_asset_price"]}
                //       hookForm
                //       control={control}
                //       data={formData.field_asset_price}
                //       register={register}
                //       setValue={setValue}
                //       watch={watch}
                //       tabsTouched={tabsTouched}
                //     />
                //   </SingleField>
                // </Tab.Panel>
              }
              {!isVariation &&
                <Tab.Panel className="pt-[30px]">
                  <CreateAssetRestrict
                    watch={watch}
                    register={register}
                    errors={errors}
                    control={control}
                    data={formData.field_user_groups}
                    name="field_user_groups"
                  />

                  <BooleanType
                    label={formData?.field_active?.label}
                    description={formData.field_active.description}
                    className="mb-[20px]"
                    name={"field_active"}
                    error={errors["field_active"]}
                    hookForm
                    data={formData.field_active}
                    register={register}
                    // setValue={setValue}
                    watch={watch}
                    fromAsset
                  />
                </Tab.Panel>}
            </>
            {isVariation === false ? (
              <><Tab.Panel className="pt-[30px]">
                {watch()?.field_asset_type === "physical" ? (
                  <CreateAssetInventory
                    edit={edit}
                    current={current}
                    body={body}
                    setBody={setBody}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    setSegmentErrors={setSegmentErrors}
                    segmentErrors={segmentErrors}
                    setRetailersAloc={setRetailersAloc}
                    retailersAloc={retailersAloc}
                    setInternalAloc={setInternalAloc}
                    internalAloc={internalAloc}
                    watch={watch}
                  />
                ) : (
                  <>
                    {" "}
                    <span
                      // className="text-sm"
                      css={css`
                      font-family: "Jost";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 20px;
                      display: inline-block;
                      padding: 0 10px;
                      margin: 0px 0 15px;
                      letter-spacing: 0.02em;
                      color: #717171;
                    `}
                    >
                      Select where this asset will be stored and fulfilled from,
                      and the total quantity you have at that location. The “In
                      Stock Quantity” will be automatically updated for all
                      assets stored with and fulfilled by the O2O Warehouse.
                    </span>
                    <div className="gap-5 flex justify-center max-h-[100px]">
                      {/* <SingleField small>
                  <EntityReferenceRevisionsAsset
                    data={formData.field_asset_brand}
                    errors={errors["field_warehouse_type"]}
                    key={"field_warehouse_type"}
                    name={"field_warehouse_type"}
                    register={register}
                    label={"Warehouse Type" + "*"}
                    setValue={setValue}
                    watch={watch}
                    token={state.token}
                    control={control}
                    placeholder="Select or type to search …"
                    nomargin
                  />
                </SingleField> */}
                      <FloatingSelect
                        value={warehouseTypeDigital?.[0].value}
                        label="Warehouse Type"
                        name="field_warehouse_type"
                        hookForm
                        register={register}
                        control={control}
                        description={
                          "Select who is fulfilling orders for this asset..."
                        }
                        required={true}
                        options={warehouseTypeDigital}
                        defaultValue={warehouseTypeDigital?.[0]}
                        // onChange={e => {
                        //   console.log(e, "e")

                        //   if (body.fulfillment_type === "o2o") {
                        //     setPrevSegmentsO2O(allocationState)
                        //   }
                        //   if (body.fulfillment_type === "in-house") {
                        //     setPrevSegmentsInHouse(allocationState)
                        //   }
                        //   if (body.fulfillment_type === "third-party") {
                        //     setPrevSegmentsThirdParty(allocationState)
                        //   }

                        //   const obj = { fulfillment_type: e?.value || null }

                        //   if (e?.value === "o2o" && prevSegmentsO2O.length) {
                        //     setAllocationState(prevSegmentsO2O)
                        //   } else if (
                        //     e?.value === "in-house" &&
                        //     prevSegmentsInHouse.length
                        //   ) {
                        //     setAllocationState(prevSegmentsInHouse)
                        //   } else if (
                        //     e?.value === "third-party" &&
                        //     prevSegmentsThirdParty.length
                        //   ) {
                        //     setAllocationState(prevSegmentsThirdParty)
                        //   } else {
                        //     setAllocationState([])
                        //   }

                        //   setBody(prevState => ({
                        //     ...prevState,
                        //     ...obj,
                        //     // fulfillment_type: e?.value || null,
                        //   }))
                        // }}
                        error={
                          formErrors.includes("fulfillment_type")
                            ? { message: "This field must have a value." }
                            : false
                        }
                      />
                      {watch().field_asset_type === "physical" ? (
                        <SingleField small>
                          <FloatingInput
                            defaultValue={0}
                            style={{ marginBottom: "0px", minHeight: "61px" }}
                            error={errors["field_stock"]}
                            name={"field_stock"}
                            register={register}
                            label={"In Stock Quantity" + "*"}
                            setValue={setValue}
                            watch={watch}
                            token={state.token}
                            control={control}
                            required
                            hookForm
                            type="number"
                            disabled={watch().field_warehouse_type === "o2o"}
                          />
                        </SingleField>
                      ) : (
                        <SingleField small>
                          <FloatingInput
                            defaultValue={0}
                            style={{ marginBottom: "0px", minHeight: "61px" }}
                            error={errors["field_stock"]}
                            name={"field_stock"}
                            register={register}
                            label={"In Stock Quantity" + "*"}
                            setValue={setValue}
                            watch={watch}
                            token={state.token}
                            control={control}
                            required
                            hookForm
                            disabled
                          />
                        </SingleField>
                      )}
                    </div>
                    {/* new */}
                    <div className="flex items-end border border-1 border-[#EBEBEB] rounded-[8px] px-4 py-4">
                      <div className="mr-6 mb-2">
                        <span className="text-strong-20 !leading-[1] mr-1">
                          {watch().field_stock || 0}
                        </span>
                        <span>in stock</span>
                      </div>
                      <div className="grow flex items-center">
                        <div
                          // className={`w-[50%] mr-1`} // Example value for internal allocation
                          // style={{
                          //   color: "#0033A1",
                          //   width: `50%`,
                          //   minWidth: "11%",
                          // }}

                          className={`w-[${stock === 0 ? 33.3 : (internalAloc / stock) * 100
                            }%] mr-1`}
                          style={{
                            color: "#0033A1",
                            width: `${stock === 0 ? 33.3 : (internalAloc / stock) * 100
                              }%`,
                            minWidth: "11%",
                          }}
                        >
                          <div className="text-[14px]">for Internal</div>
                          <div className="w-[100%] rounded-l-lg h-[40px] bg-[#0033A1] text-center text-white p-[7px]">
                            {internalAloc}
                            {/* Example value for internal allocation */}
                          </div>
                        </div>
                        <div
                          // className={`w-[30%] mr-1`} // Example value for retailers allocation
                          // style={{
                          //   color: "#009D4F",
                          //   width: `30%`,
                          //   minWidth: "11%",
                          // }}
                          className={`w-[${stock === 0 ? 33.3 : (retailersAloc / stock) * 100
                            }%] mr-1`}
                          style={{
                            color: "#009D4F",
                            width: `${stock === 0 ? 33.3 : (retailersAloc / stock) * 100
                              }%`,
                            minWidth: "11%",
                          }}
                        >
                          <div className="text-[14px]">for Retailers</div>
                          <div className="w-[100%] h-[40px] bg-[#009D4F] text-center text-white p-[7px]">
                            {retailersAloc}
                            {/* Example value for retailers allocation */}
                          </div>
                        </div>
                        <div
                          // className={`w-[20%] `}
                          // style={{
                          //   color: "rgba(0,0,0,.3)",
                          //   width: `20%`,
                          // }}
                          className={`w-[${stock === 0 ? 33.3 : (unAloc / stock) * 100
                            }%] `}
                          style={{
                            color: "rgba(0,0,0,.3)",
                            width: `${stock === 0 ? 33.3 : (unAloc / stock) * 100
                              }%`,
                          }}
                        >
                          <div className="text-[14px]">Unallocated</div>
                          <div
                            // className="w-[100%] rounded-r-lg h-[40px] bg-[#EBEBEB] text-center p-[7px]"
                            // style={{ color: "rgba(0,0,0,.3)" }}
                            className="w-[100%] rounded-r-lg h-[40px] bg-[#EBEBEB] text-center p-[7px]"
                            style={{ color: "rgba(0,0,0,.3)" }}
                          >
                            {watch().field_asset_type === "digital" &&
                              stock === 999
                              ? "infinite"
                              : unAloc}
                            {/* Example value for unallocated */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <span
                      // className="text-sm"
                      css={css`
                      font-family: "Jost";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 20px;
                      display: inline-block;
                      padding: 0 10px;
                      margin: 20px 0 15px;
                      letter-spacing: 0.02em;
                      color: #717171;
                    `}
                    >
                      Select 1 or more User Segment and assign their Per Segment
                      Availability, Per Location Order Limits and Payments. This
                      asset will only be visible to the selected User Segments.
                      Rules are prioritized from most specific type of User
                      Segment to least; Users, Stores, Companies and then
                      Regions.
                    </span>
                    {/* <AssetInventoryList /> */}
                    <Allocations
                      total={stock}
                      errors={segmentErrors}
                      allocationState={allocationState}
                      setAllocationState={setAllocationState}
                      watch={watch}
                    />
                  </>
                )}
              </Tab.Panel></>
            ) : (<>

              <Tab.Panel className="pt-[30px]">
                <FieldAssets
                  addInventory={addInventory}
                  data={formData["field_assets"]}
                  currentFormData={formData}
                  token={state.token}
                  errors={errors}
                  key={"field_assets"}
                  name={"field_assets"}
                  register={register}
                  // label={label}
                  setValue={setValue}
                  watch={watch}
                  fieldAssets={fieldAssets}
                  setFieldAssets={setFieldAssets}
                />
              </Tab.Panel>

            </>)}
            <Button
              type="submit"
              onClick={handleSubmit(
                state.locformsubmit !== null
                  ? state.locformsubmit(onSubmit)
                  : onSubmit
              )}
            >
              Save
            </Button>
            {saving === true && (
              <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden flex flex-col items-center justify-center bg-[rgba(0,0,0,.6)]">
                <div className="w-8 h-8 ease-linear border-2 border-t-2 border-gray-200 rounded-full loader"></div>
              </div>
            )}
          </Tab.Group>
        </form>
      ) : (
        <Skeleton table />
      )}
    </div>
  )
}

export default CreateAsset