import { css, jsx } from "@emotion/react"
import React, { useState, useEffect, useContext } from "react"
import MyUserSegmentationContext from "./MyUserSegmentationContext"
import {
  getMyListing,
  getMyUserSegmentationRegions,
  getMyUserSegmentationStores,
  getMyUserSegmentationCompanies,
  getMyUserSegmentationUsers,
  updateMySingleEditForm,
  addMySingleNewForm,
  deleteMySingleForm,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import DataContext from "../Data/DataContext"
import NotificationContext from "../Notification/NotificationContext"
import {
  UserGroupIcon,
  OfficeBuildingIcon,
  LocationMarkerIcon,
  ShoppingBagIcon,
} from "@heroicons/react/outline"
import { Tab } from "@headlessui/react"
import { classNames } from "../../utils"
import Regions from "../../templates/Dashboard/UserSegmentation/Regions"
import Stores from "../../templates/Dashboard/UserSegmentation/Stores"
import Companies from "../../templates/Dashboard/UserSegmentation/Companies"
import Users from "../../templates/Dashboard/UserSegmentation/Users"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import FloatingInput from "../../components/Elements/Form/FloatingInput"
import ButtonEdit from "../../components/Elements/ButtonEdit"
import ButtonDelete from "../../components/Elements/ButtonDelete"
import Button from "../../components/Elements/Button"
import { components } from "react-select"
import ButtonLite from "../../components/Elements/ButtonLite"
import Skeleton from "../../components/Skeleton"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
import Modal from "../../components/Modal/Modal"
const TabTitle = props => {
  const { name, icon, stroke } = props
  return (
    <div
      className={"group flex items-center"}
      css={css`
        &&& {
          &:hover {
            path {
              fill: ${stroke ? `none` : `#717171`};
            }
          }
        }
      `}
    >
      <props.icon
        className={`mr-3 flex-shrink-0 ${!stroke ? `h-4 w-4` : `h-[1.1rem] w-[1.1rem]`
          }`}
        css={css`
          path {
            fill: ${!stroke ? `#222` : stroke ? `none` : `#222`};
            stroke-width: ${stroke ? `1.5px` : `/**/`};
          }
        `}
        aria-hidden="true"
      />
      {name}
    </div>
  )
}

export const MyUserSegmentationProvider = props => {
  const { toggleNotification } = useContext(NotificationContext)
  const { token } = useContext(StateContext).state
  const { updateState } = useContext(DataContext)
  const { currentUserCompaniesAsOp, } = useContext(DataContext).data

  const [companies, setCompanies] = useState(currentUserCompaniesAsOp)
  const [company, setCompany] = useState(null)

  const [segments, setSegments] = useState([])
  const [loadingSegments, setLoadingSegments] = useState(true)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [create, setCreate] = useState(false)
  const [editData, setEditData] = useState({})
  const [segment, setSegment] = useState(null)
  const [editing, setEditing] = useState(false)

  const [filters, setFilters] = useState({
    regions: {
      search: "",
      country: null,
    },
    stores: {
      search: "",
      companies: [],
      regions: [],
      cities: [],
    },
    companies: {
      search: "",
      country: null,
      regions: [],
    },
    users: {
      search: "",
      job_titles: [],
      regions: [],
      user_type: null,
    },
  })

  const [data, setData] = useState({
    regions: [],
    stores: [],
    companies: [],
    users: [],
  })

  useEffect(async () => {
    if (currentUserCompaniesAsOp && currentUserCompaniesAsOp.length) {
      setCompany(currentUserCompaniesAsOp[0])
      setCompanies(currentUserCompaniesAsOp)
    }
  }, [currentUserCompaniesAsOp])

  useEffect(async () => {
    if (company) {
      setLoadingSegments(true)
      let x = await getMyListing(token, "user_group", "default", "all")
      setSegments([
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
      ])
      setLoadingSegments(false)
      setSegment(null)
      setEditData(null)
      setEditing(false)
      setCreate(false)
    }
  }, [company])

  const getRegions = async () => {
    let x = await getMyUserSegmentationRegions(token, filters.regions)
    setData(prev => ({ ...prev, regions: [...x] }))
  }

  const getCompanies = async () => {
    let x = await getMyUserSegmentationCompanies(token, filters.companies)
    setData(prev => ({ ...prev, companies: [...x] }))
  }

  const getStores = async () => {
    let x = await getMyUserSegmentationStores(token, filters.stores)
    setData(prev => ({ ...prev, stores: [...x] }))
  }

  const getUsers = async () => {
    let x = await getMyUserSegmentationUsers(
      token,
      company.value,
      filters.users
    )
    setData(prev => ({ ...prev, users: [...x] }))
  }

  const editUserSegment = async (entityId, jsonBody) => {
    // alert('x')
    let x = await updateMySingleEditForm(
      token,
      "user_group",
      "default",
      entityId,
      { json: jsonBody },
      null
    )
    if (x.status === 200) {
      let index = segments.findIndex(i => parseInt(i.id) === parseInt(entityId))
      let newSegments = [...segments]
      const ttt = { ...newSegments[index], ...jsonBody }

      // console.log(index, 'index')
      //   console.log(ttt, 'ttt')
      newSegments[index] = ttt

      setSegments([...newSegments])

      // setSegment(newSegments[index])
      // console.log(newSegments, 'newSegments')

      toggleNotification({
        content: "User segment updated successfully!",
      })
    } else {
      toggleNotification({
        content: "Something failed. Sorry about that.",
      })
    }
  }

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteSegment, setDeleteSegment] = useState(false)

  const deleteUserSegment = async entityId => {
    let x = await deleteMySingleForm(token, "user_group", "default", entityId)
    console.log(x.status, "status")
    if (x.status === 200) {
      setDeleteSegment(true)
      setSegment(null)
      setEditData({})
      setEditing(false)
      setCreate(false)
      let index = segments.findIndex(i => parseInt(i.id) === parseInt(entityId))
      let newSegments = [...segments]
      newSegments.splice(index, 1)
      setSegments([...newSegments])
      setConfirmDelete(false)

      console.log(segment, "ssssss")

      toggleNotification({
        content: "User segment deleted successfully!",
      })
    } else {
      toggleNotification({
        content: "Something failed. Sorry about that.",
      })
    }
  }

  const createUserSegment = async jsonBody => {
    let withOutTitle = { ...jsonBody }
    delete withOutTitle.title
    let body = {
      entity_title: jsonBody.title,
      json: { ...withOutTitle },
    }
    let res = await addMySingleNewForm(token, "user_group", "default", body,null)
    if (res.status === 200) {
      let x = await getMyListing(token, "user_group", "default", "all")
      setEditing(false)
      setSegments([
        ...x.filter(
          f =>
            parseInt(f.field_group_company[0].target_id) ===
            parseInt(company.value)
        ),
      ])
      toggleNotification({
        content: "User segment created successfully!",
      })
    } else {
      toggleNotification({
        content: "Something failed. Sorry about that.",
      })
    }
  }

  const tabs = [
    {
      name: (
        <TabTitle name={"Regions"} icon={LocationMarkerIcon} stroke={true} />
      ),
      content: <Regions />,
      field: "field_group_regions",
      singular: "region",
      plural: "regions",
    },
    {
      name: (
        <TabTitle name={"Companies"} icon={OfficeBuildingIcon} stroke={true} />
      ),
      content: <Companies />,
      field: "field_group_companies",
      singular: "company",
      plural: "companies",
    },
    {
      name: <TabTitle name={"Stores"} icon={ShoppingBagIcon} stroke={true} />,
      content: <Stores />,
      field: "field_group_stores",
      singular: "store",
      plural: "stores",
    },
    {
      name: <TabTitle name={"Users"} icon={UserGroupIcon} stroke={true} />,
      content: <Users />,
      field: "field_group_users",
      singular: "user",
      plural: "users",
    },
  ]
  const saveChanges = async () => {
    setSaving(true)
    if (create === true) {
      if (editData.title === "") {
        return toggleNotification({
          content: "Please enter a title for the segment",
          error: true,
        })
      }
      if (tabs[selectedIndex].field in editData === false) {
        setSaving(false)
        return toggleNotification({
          content: `Please select at least one ${tabs[selectedIndex].singular}`,
          error: true,
        })
      }
      await createUserSegment(editData)
      setCreate(false)
    } else {
      const y = await editUserSegment(parseInt(segment.value), editData)
      setEditing(false)
    }
    setSaving(false)
  }

  const cancelChanges = () => {
    if (create === true) {
      setCreate(false)
      setEditing(false)
      setSegment(null)
      setEditData({})
    } else {
      setEditing(false)
      setEditData(
        segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0]
      )
    }
  }

  useEffect(() => {
    console.log(segment)
  }, [segment])

  useEffect(() => {
    updateState("myUserGroups", segments)
  }, [segments])

  const IndicatorsContainer = ({ children, ...props }) => {
    if (editing === true || create === true) return null
    return (
      <components.IndicatorsContainer {...props}>
        <div className="flex justify-between items-center">
          <ButtonLite
            inline
            pink
            sm
            bold
            callback={() => {
              setCreate(true)
              setEditing(true)
              setSegment(null)
              setEditData({
                title: "",
                field_group_company: { target_id: company.value },
              })
            }}
          >
            Create New
          </ButtonLite>
          {children}
        </div>
      </components.IndicatorsContainer>
    )
  }

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    setSegment(null)
    setEditData({})
    setEditing(false)
    setCreate(false)
  }, [selectedIndex])

  useEffect(() => {
    if (segment !== null) {
      setEditData(
        segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0]
      )
    }
  }, [segment])
  // console.log(segments.filter(f => f[tabs[selectedIndex].field].length > 0))
  console.log(selectedIndex)
  console.log(segments)
  return (
    <MyUserSegmentationContext.Provider
      value={{
        company,
        setCompany,
        companies,
        filters,
        setFilters,
        segments,
        data,
        setData,
        getRegions,
        getStores,
        getCompanies,
        getUsers,
        editUserSegment,
        createUserSegment,
        segment,
        setSegment,
        editing,
        setEditing,
        editData,
        setEditData,
        create,
        setCreate,
        deleteUserSegment,
      }}
    >
      <div>
        <Modal
          isOpen={confirmDelete}
          closeModal={() => setConfirmDelete(false)}
          title={"Delete User Segment"}
        >
          <DeleteConfirm
            onDelete={() => deleteUserSegment(parseInt(segment.value))}
            onCancel={() => setConfirmDelete(false)}
          />
        </Modal>

        <Tab.Group
          selectedIndex={selectedIndex}
          defaultIndex={0}
          onChange={setSelectedIndex}
        >
          <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
            {tabs.map((item, k) => (
              <Tab
                key={k}
                onClick={() => setSelectedIndex(k)}
                className={({ selected }) =>
                  classNames(
                    selected
                      ? "border-[#222] text-[#222]"
                      : "border-transparent text-[#222] hover:text-gray-500",
                    "whitespace-nowrap pt-[30px] pb-[28px] px-1 border-b-2 text-med-16"
                  )
                }
              >
                {item.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-[30px]">
            {tabs.map((item, k) => {
              return (
                <Tab.Panel key={k}>
                  <div className="mb-8">
                    <div
                      className="flex max-w-[1400px] space-x-4 items-center"
                      css={css`
                        &&& {
                          > * {
                            margin-bottom: 0 !important;
                          }
                        }
                      `}
                    >
                      <div className="flex grow items-center space-x-4 lg:w-[700px] lg:max-w-[700px]">
                        <FloatingSelect
                          label="Your company"
                          options={companies}
                          name="company"
                          value={company}
                          className="!mb-0"
                          onChange={e => setCompany(e)}
                        />
                        {loadingSegments === true ? (
                          <Skeleton
                            height={60}
                            // borderRadius={6}
                            width={200}
                          />
                        ) : (
                          <>
                            {create === true ? (
                              <FloatingInput
                                label="New segment name"
                                name="new_segment_name"
                                flush
                                value={editData.title}
                                onChange={e => {
                                  setEditData(prev => ({
                                    ...prev,
                                    title: e.target.value,
                                  }))
                                }}
                                error={editData.title.length === 0}
                              />
                            ) : (
                              <FloatingSelect
                                deleteSegment={deleteSegment}
                                label="View User Segment"
                                // value={segment ? segment : ""}
                                options={
                                  segments.length
                                    ? segments
                                      .filter(
                                        f =>
                                          f[tabs[selectedIndex].field]
                                            .length > 0
                                      )
                                      .map(i =>
                                        Object.assign({
                                          value: i.id,
                                          label: i.title,
                                        })
                                      )
                                    : []
                                }
                                name="segments"
                                onChange={e => {
                                  console.log(e)
                                  setSegment(e)
                                }}
                                className="!mb-0"
                                overrideComponents={{ IndicatorsContainer }}
                              />
                            )}
                          </>
                        )}
                      </div>
                      {segment !== null && editing === false && (
                        <div className="flex space-x-2">
                          <ButtonEdit onClick={() => setEditing(!editing)} />
                          <ButtonDelete
                            onClick={() => setConfirmDelete(true)}
                          />
                        </div>
                      )}
                      {(segment !== null || create !== false) &&
                        editing === true && (
                          <div className="flex space-x-2">
                            <Button
                              widthAuto
                              red
                              onClick={saveChanges}
                              loading={saving}
                            >
                              {create === true
                                ? `Save new segment`
                                : `Save changes`}
                            </Button>
                            <Button
                              widthAuto
                              black
                              outline
                              onClick={cancelChanges}
                              loading={deleting}
                            >
                              {create === true ? `Discard` : `Cancel changes`}
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                  {item.content}
                </Tab.Panel>
              )
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
      {props.children}
    </MyUserSegmentationContext.Provider>
  )
}
export default MyUserSegmentationProvider
