/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useContext, useEffect } from "react"
import { Dialog, Transition, Tab } from "@headlessui/react"
import { classNames } from "../../utils"
import Button from "../Elements/Button"
import { useForm } from "react-hook-form"
import FloatingInput from "../Elements/Form/FloatingInput"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import DataContext from "../../context/Data/DataContext"
import StateContext from "../../context/state/StateContext"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import Textarea from "../Elements/Textarea"
import FloatingTextarea from "../Elements/Form/FloatingTextarea"
import FilterTag from "../Elements/FilterTag"
import Tags from "../Elements/Tags"
import Tag from "../Elements/Tag"
import DynamicForm from "../Forms/DynamicForm/DynamicForm"
import { RadioGroup } from "@headlessui/react"
import {
  getMySingleEditForm,
  getMySingleForm,
  updateMySingleEditForm,
  myEntitySuggestions,
} from "../../helpers/DrupalHelper"
import NotificationContext from "../../context/Notification/NotificationContext"
import ImageCardSmall from "../Elements/ImageCardSmall"
const plans = [
  {
    name: "Cover image",
    description: "A decorative cover image for the Asset.",
    value: 1,
  },
  {
    name: "Downloadable asset file",
    description: "A downloadable file available after ordering an Asset.",
    value: 2,
  },
]
export function RadioGroupAsset({ onChange }) {
  const [selected, setSelected] = useState(plans[0])

  return (
    <div className="w-1/2 mb-6">
      <RadioGroup
        value={selected}
        onChange={v => {
          setSelected(v)
          onChange(v.value)
        }}
        defaultValue={plans[0]}
      >
        <RadioGroup.Label className="text-sm font-bold mb-2 block">
          Assign as cover image or downloadable file?
        </RadioGroup.Label>
        <div className="space-y-2">
          {plans.map(plan => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              className={({ active, checked }) =>
                `${
                  active
                    ? "" //"ring-2 ring-offset-2 ring-offset-red-300 ring-white ring-opacity-60"
                    : ""
                }
                  ${checked ? "!border-[#e43d30] border" : "border-[#EBEBEB] border"}
                    relative rounded-[8px]  px-4 py-2 cursor-pointer flex focus:outline-none`
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center  w-full">
                    {checked && (
                      <div className="flex-shrink-0 text-[#e43d30] mr-3" css={css`circle {
                        fill: red;
                      }`}>
                        <CheckIcon className="w-6 h-6" />
                      </div>
                    )}
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium  ${
                            checked ? "text-[#e43d30]" : "text-gray-900"
                          }`}
                        >
                          {plan.name}
                        </RadioGroup.Label>
                        {plan.description && (
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked === true
                                ? "!text-[#e43d30] text-reg-11"
                                : "text-reg-11"
                            }`}
                          >
                            {plan.description}
                          </RadioGroup.Description>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const tabss = [
  // { name: "Locate Asset", href: "#", current: false },
  { name: "Search Asset", href: "#", current: false },
  { name: "Create & Assign To New Asset", href: "#", current: false },
]
const LocalAsset = ({ token, fileId }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm()
  const { toggleNotification } = useContext(NotificationContext)
  const {authentication} = useContext(AuthenticationContext)

  const { myBrandsAsOptions, myProductsAsOptions, myAssetsAsOptions } =
    useContext(DataContext).data
  const [show, setShow] = useState(false)

  const data = watch()
  useEffect(() => {
    if (data.brands && data.product && data.asset) {
      setShow(true)
    }
  }, [data])
  console.log(fileId)
  const [radioValue, setRadioValue] = useState(1)
  const onSubmit = async () => {
    let formData = getValues()
    let assetId = formData.asset
    if (show) {
      let assetData = await getMySingleEditForm(
        token,
        "asset",
        "default",
        assetId
      )
      console.log(assetData)
      let body = {}
      if (radioValue === 2) {
        body = {
          json: {
            field_asset_files: [
              ...assetData.field_asset_files.current_value,
              fileId,
            ],
          },
        }
      } else {
        body = {
          json: {
            field_asset_cover_image: [
              ...assetData.field_asset_cover_image.current_value,
              fileId,
            ],
          },
        }
      }
      let res = await updateMySingleEditForm(
        token,
        "asset",
        "default",
        assetId,
        body,
        authentication
      )

      // console.log(fileId, 'fileId')
      // console.log(assetData, 'fileId')

      if (res.status == 200) {
        toggleNotification({
          content: `${fileId} added to ${assetData.title.current_value} succesfully`,
        })
      } else {
        toggleNotification({
          content: `${fileId} failed  to add ${assetData.title.current_value} `,
        })
      }
    }
  }
  return (
    <>
      <div className="grid xl:grid-cols-3 xl:gap-6 mb-10">
        <FloatingSelect
          label={"Select Brand"}
          name="brands"
          control={control}
          hookForm
          // required
          options={myBrandsAsOptions}
        />
        <FloatingSelect
          label={"Select Product"}
          name="product"
          control={control}
          hookForm
          // required
          options={myProductsAsOptions}
        />
        <FloatingSelect
          label={"Select Asset"}
          name="asset"
          control={control}
          hookForm
          // required
          options={myAssetsAsOptions}
        />
      </div>
      {show && <RadioGroupAsset onChange={setRadioValue} />}
      <Button red onClick={onSubmit}>
        Save
      </Button>
    </>
  )
}
const SearchAsset = ({ token, fileId }) => {
  const [show, setShow] = useState(false)
  const [value, setValue] = useState(null)
  const [data, setData] = useState([])
  const [radioValue, setRadioValue] = useState(1)
  const { myAssetsAsOptions } = useContext(DataContext).data
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication } = useContext(AuthenticationContext)
  const onSubmit = async () => {
    console.log(fileId)
    if (show && value) {
      let assetData = await getMySingleEditForm(
        token,
        "asset",
        "default",
        value.value
      )
      let body = {}
      if (radioValue === 2) {
        body = {
          json: {
            field_asset_files: [
              ...assetData.field_asset_files.current_value,
              fileId,
            ],
          },
        }
      } else {
        body = {
          json: {
            field_asset_cover_image: [
              ...assetData.field_asset_cover_image.current_value,
              fileId,
            ],
          },
        }
      }
      let res = await updateMySingleEditForm(
        token,
        "asset",
        "default",
        value.value,
        body,
        authentication
      )
      if (res.status == 200) {
        toggleNotification({
          content: `File ${fileId} added to ${assetData.title.current_value} succesfully`,
        })
      } else {
        toggleNotification({
          content: `File ${fileId} failed to add to ${assetData.title.current_value}.`,
        })
      }
      // console.log(assetData)
    }
  }
  useEffect(() => {
    if (value) {
      setShow(true)
    }
  }, [value])

  console.log(data)
  return (
    <>
      {/* <FloatingSelect
        // name="asset"
        // options={data}
        isSearchable
      /> */}
      <FloatingSelect
        label={"Search & Select Asset"}
        // placeholder="Start typing..."
        onChange={setValue}
        type={"myAssetsAsOptions"}
        // isClearable
        isSearchable
        // name="assets"
        // hookForm
        // register={register}
        // error={errors["assets"]}
        // control={control}
        // isMulti
      />
      {show && <RadioGroupAsset onChange={setRadioValue} />}

      <Button red onClick={onSubmit}>
        Save
      </Button>
    </>
  )
}
function ManageFileBrandTab({ fileId }) {
  const [currentTab, setCurrentTab] = useState(0)
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const [tabs, setTabs] = useState([
    {
      name: "Create & Assign To New Asset",
      v: "create",
      href: "#",
      current: false,
    },
  ])
  useEffect(async () => {
    let data = await myEntitySuggestions(token, "asset", ["default"])
    if (data.length) {
      setTabs([
        // { name: "Locate Asset", v: "locate", href: "#", current: false },
        { name: "Search Asset", v: "search", href: "#", current: false },
        {
          name: "Create & Assign To New Asset",
          v: "create",
          href: "#",
          current: false,
        },
      ])
    }
  }, [])
  const {
    myBrandsAsOptions,
    myProductsAsOptions,
    myAssetsAsOptions,
    tagsAsOptions,
  } = useContext(DataContext).data
  const { token } = state

  const onSave = async (data, v) => {
    if (v === "added") {
      toggleNotification({
        content: ` Created ${data.title} & added file ${fileId} succesfully`,
      })
    } else {
      toggleNotification({
        content: `Failed to created product `,
      })
    }
  }
  const [formData, setFormData] = useState(null)

  useEffect(async () => {
    let formData = await getMySingleForm(state.token, "asset")
    console.log(formData)
    setFormData(formData)
  }, [])
  return (
    <Tab.Group defaultIndex={0} onChange={v => setCurrentTab(v)}>
      <Tab.List className="flex space-x-8">
        {tabs.map((item, k) => (
          <Tab
            key={k}
            className={({ selected }) =>
              classNames(
                selected
                  ? "border-[#222] !text-[#222]"
                  : "border-transparent !text-[#222] hover:!text-gray-500",
                "pb-[0px] border-b-2 text-reg-15"
              )
            }
          >
            {item.name}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="pt-[30px]">
        {tabs.map((v, k) => {
          // if (v.v === "locate")
          //   return (
          //     <Tab.Panel key={k}>
          //       <LocalAsset token={token} fileId={fileId} />
          //     </Tab.Panel>
          //   )
          // else
          if (v.v === "search")
            return (
              <Tab.Panel key={k}>
                <SearchAsset token={token} fileId={fileId} />
              </Tab.Panel>
            )
          else if (v.v === "create")
            return (
              <Tab.Panel key={k}>
                {formData && (
                  <DynamicForm
                    onSave={onSave}
                    addNew={true}
                    fromManageFileTab
                    fileId={fileId}
                    data={formData}
                    token={state.token}
                    name="asset"
                    bundle="default"
                  />
                )}
              </Tab.Panel>
            )
        })}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default ManageFileBrandTab
