import React from "react"
import Button from "../../Elements/Button"
import Tag from "../../Elements/Tag"
import Tags from "../../Elements/Tags"
import { Table, Tr, Td } from "../TableElements"
import ButtonEdit from "../../Elements/ButtonEdit"
import ButtonDelete from "../../Elements/ButtonDelete"
const toCapitalCase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export default function AuditTable({ data }) {
  return (
    <Table columns={["Time", "Event", "Name", "User", "Brand","IP Address", "Region"]}>
      {data.map((file, k) => {
    const now = new Date();
    const fileTime = new Date(file.timestamp);
    const timeDiffInMs = now - fileTime;
    const timeDiffInHours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
    const timeDiffInMinutes = Math.floor((timeDiffInMs / (1000 * 60)) % 60);

    const displayTime =
      timeDiffInHours < 11
        ? `${timeDiffInHours}h ${timeDiffInMinutes}m ago`
        : `${fileTime.toISOString().split("T")[0]} ${fileTime.toTimeString().split(" ")[0]}`;

    return (
      <Tr key={k}>
        <Td>{displayTime}</Td>
        <Td>{file.event}</Td>
        <Td>{file?.properties?.name}</Td>
        <Td>{file?.properties?.email}</Td>
        <Td>{file?.properties?.brandTitles}</Td>
        {/* <Td>{file?.properties?.brandIds}</Td> */}
        <Td>{file?.properties?.$ip}</Td>
        <Td>{file?.properties?.$set_once?.$initial_geoip_country_code}</Td>
      </Tr>
    );
  })}
    </Table>
  )
}
