import { navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogOut } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
import { useLocation } from "@reach/router"

const CompanyStatus = ({ location }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication } = useContext(
    AuthenticationContext
  )

  // const [notMatch, setNotMatch] = useState(false)
  const [loading, setLoading] = useState(true)
  const [companyState, setCompanyState] = useState(true)
  useEffect(() => {
    if (!authentication.currentUserData.companies.length) {
      setLoading(false)
      setCompanyState(false)
    }
  }, [authentication, location])

  const preLogOut = async () => {
    // let logoutoken = localStorage.getItem("logOutToken")
    let res = await makeLogOut(state.token,authentication)
    if (res.ok) {
      dispatchAuthentication({
        type: "LOGOUT",
      })
      dispatch({ type: "SET_INITIAL" })

      return true
    } else {
      toggleNotification({
        content: "Fail to Log Out",
      })
      return false
    }
  }
  const doLogOut = async () => {
    preLogOut().then(d => {
      if (d) navigate("/login", { state: { logOut: true } })
    })
  }
  console.log(location)

  if (!loading && !companyState) {
    return (
      <LayoutMinimal
        title={"Your Account"}
        footer={"©2022 O2O. All rights reserved."}
        metaTitle={"Your Account"}
      >
        <p className="text-reg-16 text-center mb-[20px]">
          Sorry, but there are no companies associated with your account at the
          moment. Please contact if you require further details.
        </p>

        <div className="flex justify-center items-center mt-6">
          <Button red widthAuto onClick={doLogOut}>
            Log Out
          </Button>
        </div>
      </LayoutMinimal>
    )
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
    </div>
  )
}

export default CompanyStatus
