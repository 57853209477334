/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import StoreCard from "../../components/Cards/Dashboard/StoreCard"
import LayoutDashborad from "../../components/Layout/Dashboard"
import IconAddCircle from "../../components/Elements/IconAddCircle"
import {
  getMyListing,
  getMySingleForm,
  addMySingleNewForm,
  deleteMySingleForm,
  updateMySingleEditForm,
  deleteSingleAddressBookItem,
  setAddressAsDefault,
  getUserAddressBookListing,
  getOnSiteMarketingCustomLocation,
  getRegionsListing,
  getUserAddressListing,
  getStoreForm,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import CartContext from "../../context/Cart/CartContext"
import CreateFormStore from "../../components/Forms/CreateFormStore"
import Modal from "../../components/Modal/Modal"
import {
  BoxCardGroup,
  BoxCardCreate,
  BoxCard,
} from "../../components/Cards/Dashboard/BoxCard"
import { faL } from "@fortawesome/free-solid-svg-icons"
import { StarIcon } from "@heroicons/react/outline"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import NotificationContext from "../../context/Notification/NotificationContext"
import Skeleton from "../../components/Skeleton"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import BannerSlider from "../../components/Commons/BannerSlider"
import HideIcon from "../../../src/assets/icons/hide-eye.svg"
import UnhideIcon from "../../../src/assets/icons/unhide-eye.svg"
import { hideUnhideAddressBook } from "../../helpers/DrupalHelper"
import {
  OfficeBuildingIcon,
  PhoneIcon,
  RefreshIcon,
  UserIcon,
} from "@heroicons/react/solid"
import CreateAddressModal from "../../components/CreateAddressModal"
import Button from "../../components/Elements/Button"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import SearchInput from "../../components/Elements/SearchInput"
import FilterSelect from "../../components/Elements/FilterSelect"
import MyAddressesTable from "../../components/Table/MyAddressesTable"
import FloatingCheckbox from "../../components/Elements/Form/FloatingCheckbox"
import MyOrdersContext from "../../context/MyOrders/MyOrdersContext"
import DataContext from "../../context/Data/DataContext"

export const FloatingOption = ({ selectedItems }) => {
  const [isOptionModalShow, setIsOptionModalShow] = useState(false)
  const options = [
    "Permanently delete",
    "Change type",
    "Change contact name",
    "Change company name",
    "Change phone",
  ]
  return (
    <div className="">
      <div className="bg-[#b7b7b7] relative flex items-center px-4 py-2 w-fit rounded-lg gap-x-3 border border-white shadow-md mx-auto my-0 ">
        <Button
          widthAuto
          className={
            "!bg-white !text-[#595959] !border-[#dedede] hover:!bg-[#f5f5f5] hover:!text-[#7b7b7b]"
          }
        >
          Show in cart
        </Button>
        <Button
          widthAuto
          className={
            "!bg-white !text-[#595959] !border-[#dedede] hover:!bg-[#f5f5f5] hover:!text-[#7b7b7b]"
          }
        >
          Hide from cart
        </Button>
        <Button
          widthAuto
          className={
            "!bg-white !text-[#595959] !border-[#dedede] hover:!bg-[#f5f5f5] hover:!text-[#7b7b7b]"
          }
          onClick={() => {
            setIsOptionModalShow(!isOptionModalShow)
          }}
        >
          ...
        </Button>

        <div className="absolute top-[-394px] right-0">
          {isOptionModalShow && (
            <div className="px-3 pt-5 bg-white shadow-md rounded-xl w-fit">
              <span className="!font-semibold text-med-16 !text-[#333333]">
                Edit {selectedItems?.title} Address
              </span>
              <div className="">
                {options.map((item, k) => {
                  return (
                    <div key={k}>
                      <div
                        className={`py-5 pr-20 border-b-2 ${options.length === k + 1 && "border-none"
                          }`}
                      >
                        <span className="text-reg-15 hover:text-[#009d4f] hover hover-green hover-lh-1 hover-thin cursor-pointer transition-all ml-3">
                          {item}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function BoxRender({
  v,
  k,
  supp,
  state,
  setSuppData,
  setList,
  defaultAddr,
  onEditClick,
  onDeleteClick,
  setAsDefault,
  filterValues,
  // modifiedData,
  // setModifiedData,
  isOptionShow,
  setIsOptionShow,
  selectedItems,
  setSelectedItems,
}) {
  const [hiddenStateLoader, setHiddenStateLoader] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)
  const onHideUnhideClick = async (v, hiddenState, index) => {
    console.log({ index })
    setHiddenStateLoader(true)
    const hideStateReq = await hideUnhideAddressBook(state.token, v)
    const datas = await getUserAddressListing(state.token, filterValues)

    let supplementaryData = await getUserAddressBookListing(state.token)
    setSuppData(supplementaryData)
    setList(datas)
    setSuppData(supplementaryData)

    console.log(hideStateReq)
    setHiddenStateLoader(false)
    if (hideStateReq.status === 200) {
      setHiddenStateLoader(false)
      toggleNotification({
        content:
          hiddenState === "0"
            ? "Address Hidden From Cart Sucessfully"
            : "Address Unhidden From Cart Sucessfully",
      })
    } else {
      setHiddenStateLoader(false)
      toggleNotification({
        content: "That failed. Sorry about that.",
        error: true,
      })
    }
  }
  console.log(v)
  return (
    <BoxCard
      className="transition-all group"
      key={k}
      title={
        <div className="flex flex-col">
          {v.name}
          <span className="capitalize text-reg-12 !leading-[1]">{v.type}</span>
        </div>
      }
      tr={
        parseInt(v.id) === parseInt(defaultAddr) ? (
          <span className="flex items-center text-reg-14 green">
            <StarIcon className="w-3 h-3 mr-1" /> Default address
          </span>
        ) : (
          <span
            className="transition-all text-reg-14 opacity-0 group-hover:opacity-[1] hover:text-[#009d4f] hover hover-green hover-lh-1 hover-thin cursor-pointer !flex items-center"
            onClick={() => setAsDefault(v.id)}
          >
            Set as Default
          </span>
        )
      }
      // checkBox={
      //   <FloatingCheckbox
      //     hookForm={false}
      //     gray
      //     register
      //     // onChange={e =>
      //     //   setModifiedData(
      //     //     modifiedData?.map((v, i) => {
      //     //       if (i === k) {
      //     //         return {
      //     //           ...v,
      //     //           checked: e.target.checked,
      //     //         }
      //     //       }
      //     //       return v
      //     //     })
      //     //   )
      //     // }
      //     onChange={e => {
      //       setIsOptionShow(e.target.checked)
      //       setSelectedItems(v)
      //     }}
      //     className={"!w-6 !h-6 !ml-0 cursor-pointer !border-[#222]"}
      //     // value={v.checked}
      //     value={isOptionShow && selectedItems?.id === v?.id}
      //   />
      // }
      id={v.id}
      callbackEdit={() => onEditClick(v.id)}
      callbackDelete={() => onDeleteClick(v.id)}
      field_hide_from_cart={v.field_hide_from_cart}
      extra={
        v.field_hide_from_cart === "1" ? (
          <a
            onClick={() => onHideUnhideClick(v.id, v.field_hide_from_cart, k)}
            className="mt-5 max-w-[60%] flex gap-2 text-blue-500 hover:underline cursor-pointer"
          >
            <UnhideIcon />
            <p>Unhide from Cart</p>
            {hiddenStateLoader ? (
              <RefreshIcon className="animate-spin w-7 h-7" />
            ) : (
              <></>
            )}
          </a>
        ) : (
          <a
            onClick={() => onHideUnhideClick(v.id, v.field_hide_from_cart, k)}
            className="mt-5 max-w-[50%] flex gap-2 text-blue-500 hover:underline cursor-pointer"
          >
            <HideIcon />
            <p>Hide from Cart</p>
            {hiddenStateLoader ? (
              <RefreshIcon className="animate-spin w-7 h-7" />
            ) : (
              <></>
            )}
          </a>
        )
      }
    >
      {console.log(supp)}
      {v.field_store?.length > 0 && v.field_store[0].title}
      {supp && supp.store_id === null && v.location?.length > 0 && v.location}
      {supp && supp.store_id !== null && (
        <span className="text-reg-12 block mt-3 opacity-[.7]">
          {supp.location}
        </span>
      )}
      {(supp?.first_name || supp?.last_name) && (
        <div className="flex items-center mt-2">
          <UserIcon className="w-5 h-5 mr-2" />
          {supp.first_name} {supp.last_name}
        </div>
      )}
      {supp?.company_name && (
        <div className="flex items-center mt-2">
          <OfficeBuildingIcon className="w-5 h-5 mr-2" />
          {supp?.company_name}
        </div>
      )}
      {supp?.phone && (
        <div className="flex items-center mt-2">
          <PhoneIcon className="w-5 h-5 mr-2" />
          {supp.phone}
        </div>
      )}
    </BoxCard>
  )
}

function AddressBook({ props, location }) {
  useEffect(() => {
    if (
      "state" in location &&
      location.state !== null &&
      typeof location.state === "object" &&
      "create" in location.state &&
      location.state.create === true
    ) {
      setOpenCreate(true)
    }
  }, [])

  const { filters, setFilters } = useContext(MyOrdersContext)

  const { currentUserCompaniesAsOp } = useContext(DataContext).data

  const { state } = useContext(StateContext)
  const [hero, setHero] = useState([])
  const [adCode, setAdCode] = useState("")
  const { token } = state
  const { authentication, userType } = useContext(AuthenticationContext)
  const { toggleNotification, notificationIsVisible } =
    useContext(NotificationContext)
  const { setUserAddressBook } = useContext(CartContext)
  const userId = authentication.currentUserData.i
  const { default_shipping_address } = authentication.currentUserData
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [deleteCurrent, setDeleteCurrent] = useState(null)
  const [formData, setFormData] = useState(null)
  const [defaultAddr, setDefaultAddr] = useState(default_shipping_address.id)
  const [suppData, setSuppData] = useState([])
  const [isOptionShow, setIsOptionShow] = useState(false)
  const [selectedItems, setSelectedItems] = useState({})
  const [selectedRegion, setSelectedRegion] = useState()
  const [filterValues, setFilterValues] = useState({})
  const [storeOpen, setStoreOpen] = useState(false)
  const [storeFormData, setStoreFormData] = useState(null)
  const [savedStoreId, setSavedStoreId] = useState(null)
  const [clickedOn, setClickedOn] = useState(null)

  useEffect(async () => {
    let formData = await getStoreForm(state.token)
    setStoreFormData(formData)
  }, [])

  // const [modifiedData, setModifiedData] = useState([])
  // useEffect(() => {
  //   setModifiedData(
  //     list?.map(item => {
  //       return {
  //         ...item,
  //         checked: false,
  //       }
  //     })
  //   )
  // }, [list])

  useEffect(async () => {
    // send data up the chain
    const regionGet = await getRegionsListing(token)
    let region = regionGet?.CA?.map(value => ({
      value: value.code,
      label: value.name,
    }))
    setSelectedRegion(region)
  }, [])

  // console.log(selectedRegion)
  useEffect(() => {
    // send data up the chain
    setUserAddressBook(suppData)
  }, [suppData])
  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = v => {
    setDeleteCurrent(v)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrent(null)
    setOpen(false)
  }
  console.log({ formData })

  // console.log({ filters })

  const typeOptions = formData?.field_address_type?.allowed_values?.map(
    value => ({
      value: value.key,
      label: value.title,
    })
  )
  // console.log(options)

  const onSave = async (data, v) => {
    console.log(data, "data")
    if (data.field_address_type === "store" && data.field_store === "")
      return toggleNotification({
        content: "Please select a store.",
        error: true,
      })
    if (v === "added") {
      setOpenCreate(false)
      const datas = await getUserAddressListing(state.token, filterValues)

      let supplementaryData = await getUserAddressBookListing(state.token)
      setSuppData(supplementaryData)

      setList(datas)
      setSuppData(supplementaryData)
      toggleNotification({ content: "Address Added Sucessfully" })
    } else if (v === "edited") {
      setList([...data])
      setOpen(false)
      setEditCurrent(null)
      const datas = await getUserAddressListing(state.token, filterValues)
      setList(datas)
      let supplementaryData = await getUserAddressBookListing(state.token)
      setSuppData(supplementaryData)
      toggleNotification({ content: "Address Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed" })
    }
  }

  //store save
  const onStoreSave = async (data, v) => {
    // alert(v)
    // console.log(data)
    if (v === "added") {
      let res = await addMySingleNewForm(
        state.token,
        "store",
        "retail_store",
        data,
        authentication
      )
      if (res.status === 200) {
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">Store Added</p>
          ),
        })
        const x = await res.json()
        if (x.id?.[0]?.value) setSavedStoreId(x.id?.[0]?.value)
      } else
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">
              fail to add Store
            </p>
          ),
        })
      // let newList = [...list, data]
      // setList(await getMyListing(state.token, "store", "retail_store"))

      //if clickedOn is create, then open create modal again  and if
      //clickedOn is edit, then open edit modal again
      if (clickedOn === "create") {
        setStoreOpen(false)
        setOpenCreate(true)
      } else if (clickedOn === "edit") {
        setStoreOpen(false)
        setOpen(true)
      }
    } else {
      toggleNotification({
        content: (
          <p className="text-sm font-medium text-red-600">{data}Failed </p>
        ),
      })
    }
  }

  useEffect(async () => {
    const data = await getUserAddressListing(state.token, filterValues)
    setList([...data])
    let formData = await getMySingleForm(state.token, "address", "default")

    setFormData(formData)

    let supplementaryData = await getUserAddressBookListing(state.token)
    setSuppData(supplementaryData)
  }, [filterValues])

  const setAsDefault = async id => {
    const d = await setAddressAsDefault(token, id)
    if (d.status === 200) {
      const res = await d.json()
      toggleNotification({
        content: `Success! You default shipping address has been updated to ${res.title}.`,
      })
      setDefaultAddr(id)
    } else {
      toggleNotification({
        content: "That failed. Sorry about that.",
        error: true,
      })
    }
  }
  const handleDelete = async () => {
    const data = await deleteSingleAddressBookItem(state.token, deleteCurrent)

    if (data.status === 200) {
      toggleNotification({
        content: "Address deleted successfully",
      })
      const x = await getUserAddressListing(state.token, filterValues)
      setList(x)
    } else {
      toggleNotification({
        content: "That failed. Sorry about that.",
        error: true,
      })
    }
    let supplementaryData = await getUserAddressBookListing(state.token)
    setSuppData(supplementaryData)
    setDeleteCurrent(null)
    setOpen(false)
  }

  useEffect(() => {
    if (!userType.brandManager && !userType.brandEditor) {
      getOnSiteMarketingCustomLocation(token, "20").then(res => {
        res.items?.length && setHero(res.items[0]["items"])
      })
      getOnSiteMarketingCustomLocation(token, "7,8,9,10").then(res => {
        res.items?.length && setAdCode(res.items)
      })
    }
  }, [])

  const sortOptions = [{ label: "All Visibility", value: "all visibility" }]

  // const isOptionShow = modifiedData?.some(item => item.checked === true)
  // const selectedItems = modifiedData?.filter(item => item.checked === true)
  // console.log(modifiedData)
  // console.log(selectedItems)

  // const handleFilterChange = (key, value) => {
  //   setFilterValues(prevValues => ({
  //     ...prevValues,
  //     [key]: value,
  //   }))
  // }

  const handleFilterChange = (key, value) => {
    setFilterValues(prevValues => {
      // Create a copy of the previous values
      const updatedValues = { ...prevValues }

      // If the value is undefined, delete the key from the updatedValues
      if (value === undefined) {
        delete updatedValues[key]
      } else {
        // Otherwise, update the key with the new value
        updatedValues[key] = value
      }

      return updatedValues
    })
  }

  console.log(filterValues)

  function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }
    return target;
  }

  return (
    <div
      className={`pt-[20px] ${userType.brandEditor ? "mt-[130px]" : !userType.brandManager && "mt-20"
        }`}
    >
      {(userType.retailerManager || userType.retailerEditor) && (
        <BannerSlider items={hero} />
      )}

      <LayoutDashborad
        metaTitle="Address Book"
      // heading={"Address Book"}
      // subheading={
      //   "Manage which addresses you would like to be able to ship to."
      // }
      >
        <div className="container mx-auto mt-0 mb-12">
          <div>
            <span className="transition-all text-semibold-20">
              Manage which addresses you would like to be able ship to.
            </span>
          </div>
          <Button
            red
            onClick={() => setOpenCreate(true)}
            widthAuto
            className="mt-6"
          >
            Add New Address
          </Button>
          <div className="w-full border border-[#EBEBEB] rounded-[8px] px-6 py-6 mt-8">
            <div className="grid items-center grid-cols-5 gap-5 max-md:grid-cols-2 max-lg:grid-cols-3 max-[450px]:grid-cols-1">
              <SearchInput
                placeholder={"Search for anything ..."}
                onChange={e => {
                  console.log(e.target.value)
                  handleFilterChange("search", e.target.value)
                }}
              />
              <FloatingSelect
                label={"Type"}
                className={"!mb-0"}
                options={typeOptions}
                onChange={e => {
                  console.log(e)
                  // handleFilterChange("type", e.value)
                  // const value = e ? e.value : null
                  // console.log(value)
                  handleFilterChange("type", e?.value)
                }}
              />
              <FloatingSelect
                label={"Company"}
                className={"!mb-0"}
                options={currentUserCompaniesAsOp}
                onChange={e => {
                  // console.log(e.value)
                  handleFilterChange("company", e?.value)
                }}
              />
              <FloatingSelect
                label={"Region"}
                className={"!mb-0"}
                options={selectedRegion}
                onChange={e => {
                  // console.log(e.value)
                  handleFilterChange("region", e?.value)
                }}
              />
              <FilterSelect
                defaultValue={sortOptions[0]}
                last
                options={[
                  {
                    label: "All Visibility",
                    value: "all visibility",
                  },
                  {
                    label: "Visible",
                    value: "visible",
                  },
                  {
                    label: "Invisible",
                    value: "invisible",
                  },
                ]}
                label="Status"
                onChange={v => {
                  handleFilterChange("status", v)
                }}
                // onChange={e => console.log(e)}
                wFit={"w-fit"}
                className={"w-fit"}
                textColor={"!text-[#222] font-semibold"}
              />
            </div>
          </div>
          {list === null ? (
            <Skeleton boxCard />
          ) : list.length > 0 ? (
            <div className="mt-4">
              <span className="">
                Showing {list.length} of {list.length} results
              </span>
              <div className="block mt-2 max-lg:hidden">
                <MyAddressesTable
                  data={list}
                  state={state}
                  setSuppData={setSuppData}
                  suppData={suppData}
                  setList={setList}
                  defaultAddr={defaultAddr}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                  setAsDefault={setAsDefault}
                  isOptionShow={isOptionShow}
                  setIsOptionShow={setIsOptionShow}
                  // setModifiedData={setModifiedData}
                  // modifiedData={modifiedData}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              </div>

              <div className="lg:hidden max-lg:mt-2 max-lg:flex max-lg:flex-col max-lg:gap-5">
                {list === null ? (
                  <Skeleton boxCard />
                ) : list.length > 0 ? (
                  list?.map((v, k) => {
                    const supp = suppData.filter(
                      f => parseInt(f.id) === parseInt(v.id)
                    )[0]
                    console.log(filterValues)
                    console.log(list)
                    return (
                      <BoxRender
                        v={v}
                        k={k}
                        supp={supp}
                        state={state}
                        setSuppData={setSuppData}
                        setList={setList}
                        defaultAddr={defaultAddr}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                        setAsDefault={setAsDefault}
                        filterValues={filterValues}
                        // setModifiedData={setModifiedData}
                        // modifiedData={modifiedData}
                        isOptionShow={isOptionShow}
                        setIsOptionShow={setIsOptionShow}
                        // setModifiedData={setModifiedData}
                        // modifiedData={modifiedData}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                      />
                    )
                  })
                ) : (
                  "No items to display"
                )}
              </div>
            </div>
          ) : (
            "No items to display"
          )}

          {isOptionShow && (
            <div className="sticky hidden max-md:block bottom-2 z-[99999]">
              <FloatingOption selectedItems={selectedItems} />
            </div>
          )}
          {/* <BoxCardGroup>
            <BoxCardCreate
              title={"Create New Address Card"}
              callback={() => setOpenCreate(true)}
            />

            {list === null ? (
              <Skeleton boxCard />
            ) : list.length > 0 ? (
              list.map((v, k) => {
                const supp = suppData.filter(
                  f => parseInt(f.id) === parseInt(v.id)
                )[0]
                return (
                  <BoxRender
                    v={v}
                    k={k}
                    supp={supp}
                    state={state}
                    setSuppData={setSuppData}
                    setList={setList}
                    defaultAddr={defaultAddr}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                    setAsDefault={setAsDefault}
                  />
                )
              })
            ) : (
              "No items to display"
            )}
          </BoxCardGroup> */}
        </div>
        <CreateAddressModal
          title={"Create New Address Card"}
          openCreate={openCreate}
          closeModal={() => {
            if (!notificationIsVisible) setOpenCreate(false)
          }}
          onSave={onSave}
          formData={formData}
          suppData={suppData}
          onExtraClick={() => {
            if (!notificationIsVisible) setOpenCreate(false)
            setStoreOpen(true)
            setClickedOn("create")
          }}
          savedStoreId={savedStoreId}
        />
        {
          //   <Modal
          //   title={"Create New Address Card"}
          //   isOpen={openCreate}
          //   closeModal={() => {
          //     if (!notificationIsVisible) setOpenCreate(false)
          //   }}
          // >
          //   {formData !== null && (
          //     <DynamicForm
          //       onSave={onSave}
          //       addNew={true}
          //       data={formData}
          //       token={state.token}
          //       name="address"
          //       bundle="default"
          //       dynamicFields={[
          //         {
          //           subject: "field_store",
          //           controller: "field_address_type",
          //           if_equals: ["store"],
          //         },
          //         {
          //           subject: "field_location",
          //           controller: "field_address_type",
          //           if_equals: ["other", "locker", "home", "office"],
          //         },
          //         {
          //           subject: "field_first_name",
          //           controller: "field_address_type",
          //           if_equals: ["other", "locker", "home", "office"],
          //         },
          //         {
          //           subject: "field_last_name",
          //           controller: "field_address_type",
          //           if_equals: ["other", "locker", "home", "office"],
          //         },
          //         {
          //           subject: "field_company_name",
          //           controller: "field_address_type",
          //           if_equals: ["other", "locker", "home", "office"],
          //         },
          //       ]}
          //       suppData={suppData}
          //     />
          //   )}
          // </Modal>
        }

        {open && editCurrent && (
          <Modal
            isOpen={open}
            closeModal={() => {
              setEditCurrent(null)
              setOpen(false)
            }}
            title={"Edit Address Card"}
          >
            <EditFormTemplate
              edit
              id={editCurrent}
              token={state.token}
              bundle="default"
              name={"address"}
              list={list}
              onSave={onSave}
              suppData={suppData}
              onExtraClick={() => {
                setOpen(false)
                setStoreOpen(true)
                setClickedOn("edit")
              }}
              savedStoreId={savedStoreId}
              dynamicFields={[
                {
                  subject: "field_store",
                  controller: "field_address_type",
                  if_equals: ["store"],
                },
                {
                  subject: "field_location",
                  controller: "field_address_type",
                  if_equals: ["other", "locker", "home", "office"],
                },
                {
                  subject: "field_first_name",
                  controller: "field_address_type",
                  if_equals: ["other", "locker", "home", "office", "store"],
                },
                {
                  subject: "field_last_name",
                  controller: "field_address_type",
                  if_equals: ["other", "locker", "home", "office", "store"],
                },
                {
                  subject: "field_company_name",
                  controller: "field_address_type",
                  if_equals: ["other", "locker", "home", "office"],
                },
              ]}
            />
          </Modal>
        )}
        {open && deleteCurrent && (
          <Modal
            isOpen={open}
            closeModal={closeDeleteModal}
            title={"Delete Address Card"}
          >
            <DeleteConfirm
              onDelete={handleDelete}
              onCancel={closeDeleteModal}
            />
          </Modal>
        )}
        {openAfterSave && (
          <Modal
            isOpen={openAfterSave}
            closeModal={() => {
              setOpenAfterSave(false)
            }}
          >
            <h2>{contentAfterSave}</h2>
          </Modal>
        )}
        <Modal
          title={"Create New Store"}
          isOpen={storeOpen}
          closeModal={() => {
            if (!notificationIsVisible) setStoreOpen(false)
          }}
        >
          {storeFormData !== null && (
            <DynamicForm
              onSave={onStoreSave}
              addNew={true}
              data={storeFormData}
              token={state.token}
              name="store"
              bundle="retail_store"
            />
          )}
        </Modal>
      </LayoutDashborad>
    </div>
  )
}

export default AddressBook
