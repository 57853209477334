/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import { navigate } from "gatsby"

import React, { useState, createContext, useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import FloatingSelect from "../components/Elements/Form/FloatingSelect"
import CustomLocationForm from "../components/Forms/CustomLocationForm"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import AccessDenied from "../components/AccessDenied"
import StateContext from "../context/state/StateContext"
import {
  addMySingleNewForm,
  getRetailStoresLocation,
  getUser,
  makeLogin,
  makeLogOut,
  registerRetailer,
  retailerOnboarding,
} from "../helpers/DrupalHelper"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Button from "../components/Elements/Button"
import FloatingDatePicker from "../components/Elements/Form/FloatingDatePicker"
import AsyncFloatingSelect from "../components/Elements/Form/AsyncFloatingSelect"
import Logo from "../assets/logos/o2o-logo-r.png"
import FloatingCheckbox from "../components/Elements/Form/FloatingCheckbox"
import { classNames } from "../utils"
import GeoLocations from "../components/Forms/DynamicForm/FormTypes/GeoLocations"

export const FormContext = createContext()

const QuestionTitle = props => (
  <h4 className="text-med-16 mb-4">{props.children}</h4>
)
const FormProvider = ({ children }) => {
  const [data, setData] = useState({})

  const setFormValues = values => {
    setData(prevValues => ({
      ...prevValues,
      ...values,
    }))
  }

  return (
    <FormContext.Provider value={{ data, setFormValues }}>
      {children}
    </FormContext.Provider>
  )
}

const useFormData = () => useContext(FormContext)
export const FormArrayContext = createContext()

const FormArrayProvider = ({ children }) => {
  const [arrayData, setData] = useState([])
  const [oldRetailerIndex, setIndex] = useState(null)

  const setFormArrayValues = values => {
    setData(values)
  }
  const setOldRetailer = v => {
    if (!oldRetailerIndex) setIndex(arrayData.length)
    let index = oldRetailerIndex ? oldRetailerIndex : arrayData.length
    let arr = arrayData
    arr[index] = v
    setData(values => [...values])
  }

  return (
    <FormArrayContext.Provider
      value={{ arrayData, setFormArrayValues, setOldRetailer }}
    >
      {children}
    </FormArrayContext.Provider>
  )
}

const useFormArrayData = () => useContext(FormArrayContext)
const fontStyles = `font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #717171;`
const FormOne = ({
  retailerName,
  formStep,
  nextFormStep,
  retailerId,
  updateRetailerName,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    getValues,
  } = useForm()
  const { setFormValues } = useFormData()
  const [showNextQ, setShowNextQ] = useState(false)
  const onSubmit = async data => {
    if (newRetailerName !== null) updateRetailerName(newRetailerName)
    if (data?.retailer_manual) updateRetailerName(data?.retailer_manual)
    if (!data.retailer_id)
      setFormValues({ ...data, retailer_id: retailerId, working: "yes" })
    else setFormValues({ ...data, working: "yes" })
    nextFormStep()
  }
  const onChange = () => {
    let data = getValues()

    if (data.working === "no") setShowNextQ(true)
    else setShowNextQ(false)
  }

  const [newRetailerName, setNewRetailerName] = useState(null)
  const [customActive, setCustomActive] = useState(false)
  // console.log(customActive)
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
      style={formStep === 0 ? { display: "block" } : { display: "none" }}
    >
      <QuestionTitle>Do you still work for {retailerName}?</QuestionTitle>

      <div className="flex flex-col mb-6">
        <label htmlFor="field-yes" className="flex items-center">
          <input
            {...register("working", { required: "Please select" })}
            type="radio"
            name="working"
            value="yes"
            className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
            id="field-yes"
          />
          Yes
        </label>
        <label htmlFor="field-no" className="flex items-center">
          <input
            {...register("working", { required: "Please select" })}
            type="radio"
            name="working"
            value="no"
            className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
            id="field-no"
          />
          No
        </label>
      </div>
      {errors["working"] && (
        <span className="text-red-600">{errors["working"].message}</span>
      )}

      {/* {showNextQ && (
        <AsyncFloatingSelect
          label="Select the Retailer you do work for."
          name="retailer_id"
          hookForm
          register={register}
          error={errors["retailer_id"]}
          control={control}
          required
          body={{
            entity: "retailer",
            bundle: "default",
          }}
          customOnChange={value => {
            // nextFormStep()
            setNewRetailerName(value.label)
            // console.log(value)
          }}
        />
      )} */}
      {showNextQ && (
        <>
          {!customActive ? (
            <AsyncFloatingSelect
              label="Select the Retailer you do work for."
              name="retailer_id"
              hookForm
              register={register}
              error={errors["retailer_id"]}
              control={control}
              required
              body={{
                entity: "retailer",
                bundle: "default",
              }}
              notFoundButton={true}
              notFoundButtonTitle="Can't find your retailer?"
              notFoundButtonF={() => {
                setCustomActive(true)
                // setStoreLocation([])
                // setShowLoc(true)
                // setShowAssociation(true)
              }}
              customOnChange={async v => {
                // setShowAssociation(true)
                setNewRetailerName(v.label)

                // setShowLoc(true)
                // let data = await getRetailStoresLocation(
                //   state.token,
                //   v.value
                // )
                // console.log(data)
                // setStoreLocation(data)
                // setValue("location", data[0].id)
              }}
            />
          ) : (
            <FloatingInput
              label="Retailer Name"
              hookForm
              name="retailer_manual"
              // type="email"
              register={register}
              error={errors["retailer_manual"]}
              required

              // description={"Personal first name."}
            />
          )}
        </>
      )}

      <div className="flex flex-col justify-end items-end mt-6">
        <Button type="submit" red widthAuto>
          Next
        </Button>
      </div>
    </form>
  )
}
const FormTwo = ({ retailerName, formStep, nextFormStep }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    getValues,
  } = useForm()
  const { setFormValues } = useFormData()
  const [showNextQ, setShowNextQ] = useState(false)
  const onSubmit = async data => {
    if (data.store == "Head Office") {
      setFormValues({ ...data, admin: "yes" })
      nextFormStep(4)
    } else {
      nextFormStep()

      setFormValues(data)
    }
  }
  const onChange = () => {
    let data = getValues()

    if (data.retailersQ === "yes") setShowNextQ(true)
    else setShowNextQ(false)
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
      style={formStep === 1 ? { display: "block" } : { display: "none" }}
    >
      <QuestionTitle>
        Does {retailerName} have more than one store?
      </QuestionTitle>

      <div className="flex flex-col mb-6">
        <label htmlFor="retailersQ-no">
          <input
            {...register("retailersQ", { required: "Please select" })}
            type="radio"
            name="retailersQ"
            value="no"
            id="retailersQ-no"
            className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
          />
          No
        </label>{" "}
        <label htmlFor="retailersQ-yes">
          <input
            {...register("retailersQ", { required: "Please select" })}
            type="radio"
            name="retailersQ"
            value="yes"
            id="retailersQ-yes"
            className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
          />
          Yes
        </label>
      </div>
      {errors["retailersQ"] && (
        <span className="text-red-600">{errors["retailersQ"].message}</span>
      )}

      {showNextQ && (
        <div className="block mb-6">
          <p>Do you work at a head office or in store(s)?</p>
          <label htmlFor="stores-yes">
            <input
              {...register("store", { required: "Please select" })}
              type="radio"
              name="store"
              value="Head Office"
              id="stores-yes"
              className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
            />
            Head Office
          </label>
          <br />
          <label htmlFor="stores-no">
            <input
              {...register("store", { required: "Please select" })}
              type="radio"
              name="store"
              value="In Store"
              id="stores-no"
              className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
            />
            In Store
          </label>
        </div>
      )}
      {errors["store"] && (
        <span className="text-red-600">{errors["store"].message}</span>
      )}

      <div className="flex flex-col justify-end items-end mt-6">
        <Button type="submit" red widthAuto>
          Next
        </Button>
      </div>
    </form>
  )
}
const FormThree = ({
  options,
  formStep,
  nextFormStep,
  loadingLocationsData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    unregister,
    getValues,
  } = useForm()
  const { setFormValues } = useFormData()
  const { state } = useContext(StateContext)
  const {authentication} = useContext(AuthenticationContext)
  const [showNextQ, setShowNextQ] = useState(false)
  const [storeCustomLoc, setStoreCustomLoc] = useState(false)
  // console.log(errors, watch())
  // alert(loadingLocationsData)
  const onSubmit = async data => {
    // console.log(data)

    if (data?.customBodyLocation) {
      let body = data
      let locationBody = data?.customBodyLocation?.body
      if (locationBody) {
        // console.log(locationBody)
        let res = await addMySingleNewForm(
          state.token,
          "location",
          "default",
          locationBody,
          authentication
        )
        if (res.status === 200) {
          let dataL = await res.json()
          // console.log(data)
          let id = dataL.id[0].value
          setFormValues({ location: id })

          // body.json[locationFieldName] = id
          // body.customBodyLocation = id
        }
      }
    } else setFormValues({ location: data?.location })

    nextFormStep()
  }

  const onSaveLoc = data => {
    let values = Object.values(data)
    let strAdd = values.toLocaleString().replaceAll(",", ", ")
    // console.log(values, strAdd)
    setFormValues({
      location: {
        entity_title: strAdd,
        json: {
          field_location_address: { ...data },
          field_location_shortname: strAdd,
        },
      },
    })
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={formStep === 2 ? { display: "block" } : { display: "none" }}
    >
      <QuestionTitle>
        Which of the following locations do you work at?
      </QuestionTitle>

      {/* <AsyncFloatingSelect
        label="Select the location you work at."
        name="location"
        hookForm
        register={register}
        error={errors["location"]}
        control={control}
        required
        body={{
          entity: "location",
          entity_type: ["default"],
        }}
        customOnChange={() => {
          nextFormStep()
        }}
      /> */}
      {/* {storeCustomLoc && <input className="hidden" type="text" />} */}
      {loadingLocationsData === true && "Loading Your Stores..."}
      {loadingLocationsData === false && (
        <>
          {storeCustomLoc === false && (
            <>
              {!options || !options?.length ? (
                <>
                  <FloatingSelect
                    label="Select the location you work at."
                    name="location"
                    options={options.map(v => ({
                      value: v.id,
                      label: v.location,
                    }))}
                    control={control}
                    required
                    notFoundButton={true}
                    notFoundButtonTitle="Can't find your Location?"
                    notFoundButtonF={() => {
                      setStoreCustomLoc(true)
                      unregister("location")
                    }}
                    hookForm
                  />
                  {/* Geolocations... */}
                  {/* // <GeoLocations
            //   hookForm
            //   errors={errors}
            //   key={"location"}
            //   name={"location"}
            //   register={register}
            //   label={"Store Location"}
            //   setValue={setValue}
            //   watch={watch}
            //   token={state.token}
            //   unregister={unregister}
            // /> */}
                </>
              ) : (
                <FloatingSelect
                  label="Select the location you work at."
                  name="location"
                  options={options.map(v => ({
                    value: v.id,
                    label: v.location,
                  }))}
                  control={control}
                  required
                  notFoundButton={true}
                  notFoundButtonTitle="Can't find your Location?"
                  notFoundButtonF={() => {
                    setStoreCustomLoc(true)
                    unregister("location")
                  }}
                  hookForm
                />
              )}
            </>
          )}

          {storeCustomLoc === true && <CustomLocationForm onSave={onSaveLoc} />}
        </>
      )}
      {/* <FloatingSelect
        label="Select the location you work at."
        name="location"
        options={options.map(v => ({ value: v.id, label: v.location }))}
        control={control}
        required
        hookForm
      /> */}
      <div className="flex flex-col justify-end items-end mt-6">
        <Button type="submit" red widthAuto>
          Next
        </Button>
      </div>
    </form>
  )
}
const FormFour = ({ formStep, nextFormStep, index, setNextFlow }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    getValues,
  } = useForm()
  const { setFormValues } = useFormData()
  const [showNextQ, setShowNextQ] = useState(false)
  const onSubmit = async data => {
    // console.log(data)
    setFormValues(data)
    nextFormStep()
    setNextFlow(index + 1)
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={formStep === 3 ? { display: "block" } : { display: "none" }}
    >
      <QuestionTitle>
        Should you be an Admin of your store's O2O account?
      </QuestionTitle>

      <div className="flex flex-col mb-6">
        <label htmlFor="field-yes">
          <input
            {...register("admin", { required: "Please select" })}
            type="radio"
            name="admin"
            value="yes"
            id="field-yes"
            className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
          />
          Yes
        </label>
        <label htmlFor="field-no">
          <input
            {...register("admin", { required: "Please select" })}
            type="radio"
            name="admin"
            value="no"
            id="field-no"
            className="mr-2 focus:ring-[#222] h-4 w-4 text-[#222] border-[#222]"
          />
          No
        </label>
      </div>
      <div className="flex flex-col justify-end items-end mt-6">
        <Button type="submit" red widthAuto>
          Done
        </Button>
      </div>
    </form>
  )
}
const Forms = ({
  retailerName1,
  retailerId,
  index,
  setNextFlow,
  show,
  flows,
}) => {
  const [formStep, setFormStep] = useState(0)
  const [retailerName, setRetailerName] = useState(retailerName1)
  const [locationsData, setLocationsData] = useState(null)
  const [loadingLocationsData, setLoadingLocationsData] = useState(true)
  const { state, dispatch } = useContext(StateContext)
  console.log({ state })
  const { data, setFormValues } = useFormData()
  const { arrayData, setFormArrayValues, setOldRetailer } = useFormArrayData()

  useEffect(async () => {
    if (retailerId) {
      setFormValues({ retailer_id: retailerId })

      let data = await getRetailStoresLocation(state.token, retailerId)
      // console.log(data)
      setLocationsData(data)
    }
  }, [])
  useEffect(async () => {
    if (retailerId) {
      setFormValues({ retailer_id: retailerId })

      let data = await getRetailStoresLocation(state.token, retailerId)
      console.log(data, "settingThisData")
      setLocationsData(data)
      setLoadingLocationsData(false)
    }
  }, [retailerId])
  useEffect(async () => {
    if (formStep >= 4) {
      setNextFlow(index + 1)
    }
  }, [])
  useEffect(async () => {
    if (data.retailer_id && data.retailer_id !== retailerId) {
      let dataa = await getRetailStoresLocation(state.token, data.retailer_id)
      // console.log(dataa)
      setLocationsData(dataa)
    }
    if (data?.retailer_manual) setLocationsData(null)
  }, [data])
  useEffect(() => {
    if (retailerName1 !== retailerName) {
      setOldRetailer({ retailer_id: retailerId, working: "no" })
    }
  }, [retailerName])
  useEffect(async () => {
    let array = [...arrayData]
    if (data?.retailer_manual) delete data.retailer_id
    array[index] = data
    setFormArrayValues(array)
  }, [data])
  const nextFormStep = v =>
    setFormStep(currentStep => (v ? v : currentStep + 1))

  const prevFormStep = () => setFormStep(currentStep => currentStep - 1)
  // console.log(data)

  const submitData = async () => {
    // console.log(arrayData)
    // console.log("arrayData")
    const res = await retailerOnboarding(state.token, arrayData)
    if (res.ok) {
    }
    // console.log(res)
  }
  const { navigation, dispatchAuthentication, authentication } = useContext(
    AuthenticationContext
  )

  useEffect(() => {
    if (formStep >= 4 && index === flows - 1) {
      submitData(arrayData)
    }
  }, [formStep])

  if (!show) return null

  const Complete = () => {
    setTimeout(() => {
      // navigate('/')
      window.location.href = "/"
    }, [5000])

    return (
      <>
        <p className="text-reg-16 mb-5">
          Thank you for updating your account details with us. Let's take you to
          the home page!
        </p>
        {/*<Button red full onClick={() => }>
        Get Started with O2O
      </Button>*/}
        {/*<pre>{JSON.stringify(arrayData)}</pre>*/}
      </>
    )
  }
  const CompletePending = () => {
    return (
      <>
        <p className="text-reg-16 mb-5">
          Thank you for updating your account details with us. We will be in
          touch within 48 hours after we have confirmed your new account
          details.
        </p>
        <Button red full onClick={() => makeLogOut(state.token,authentication)}>
          Log out
        </Button>
        {/*<pre>{JSON.stringify(arrayData)}</pre>*/}
      </>
    )
  }
  return (
    <>
      <div className="my-6 w-full border border-[#EBEBEB] rounded-[8px] p-4">
        {formStep > 0 && formStep < 4 ? (
          <Button red outline onClick={prevFormStep}>
            Back
          </Button>
        ) : (
          <Button disabled>Back</Button>
        )}
        <div className="flex items-center justify-between mt-4 mb-6">
          <h1 className="text-strong-20 text-center">{retailerName}</h1>
        </div>

        {formStep >= 0 && (
          <FormOne
            retailerName={retailerName}
            formStep={formStep}
            nextFormStep={nextFormStep}
            retailerId={retailerId}
            updateRetailerName={setRetailerName}
            // updateRetailerId={setRetailerId}
          />
        )}
        {formStep >= 1 && (
          <FormTwo
            retailerName={retailerName}
            formStep={formStep}
            nextFormStep={nextFormStep}
          />
        )}
        {formStep >= 2 && (
          <FormThree
            retailerName={retailerName}
            formStep={formStep}
            nextFormStep={nextFormStep}
            options={locationsData}
            loadingLocationsData={loadingLocationsData}
          />
        )}
        {formStep >= 3 && (
          <FormFour
            retailerName={retailerName}
            formStep={formStep}
            nextFormStep={nextFormStep}
            index={index}
            setNextFlow={setNextFlow}
          />
        )}

        {formStep >= 4 && (
          <>
            {arrayData.length === 1 && arrayData[0].working === "yes" ? (
              <Complete />
            ) : (
              <CompletePending />
            )}
          </>
        )}
      </div>
    </>
  )
}
const Onboarding = () => {
  const { authentication, dispatchAuthentication } = useContext(
    AuthenticationContext
  )
  const { retailers, onboarding } = authentication.currentUserData
  const [index, setIndex] = useState(0)
  const [startNow, setStartNow] = useState(false)
  // console.log(index)

  const setNextFlow = v => {
    if (retailers.length > v) setIndex(v)
  }

  if (onboarding === 1) navigate("/")
  if (!retailers || retailers.length === 0) {
    return <AccessDenied />
  }
  return (
    <>
      <Helmet title={"Onboarding | O2O"} titleTemplate={"%s"} />

      <div className="flex items-center bg-[#f9f9f9] min-h-screen">
        <div className="w-full my-[30px] max-w-[600px] m-auto bg-white rounded-[8px] border border-primaryBorder shadow-default py-10 px-16">
          <a className="flex justify-center" href="https://useo2o.com">
            <img src={Logo} className="w-[60px]" />
          </a>
          <h1 className="text-strong-20 red mt-2 mb-6 text-center">
            {startNow === true
              ? "Confirm your details"
              : `Welcome back, ${authentication.currentUserData.firstname}.`}
          </h1>

          {startNow === true ? (
            <FormArrayProvider>
              {retailers.length > 0 &&
                retailers
                  // .filter((f, k) => {
                  //   if (k === 0) return true
                  //   return false
                  // })
                  .map((v, k) => {
                    // console.log(v)
                    return (
                      <FormProvider key={k}>
                        <Forms
                          setNextFlow={setNextFlow}
                          retailerName1={v.title}
                          retailerId={v.id}
                          index={k}
                          show={index === k}
                          flows={retailers.length}
                        />
                      </FormProvider>
                    )
                  })}
            </FormArrayProvider>
          ) : (
            <>
              <p className="text-reg-16 text-center mb-4">
                We need to ask you some questions to make sure the details we
                hold about your account are still correct.
              </p>
              <p className="text-reg-16 text-center">
                This will only take a minute.
              </p>
              <div className="max-w-[300px] mx-auto my-6">
                <Button red full onClick={() => setStartNow(true)}>
                  Get Started
                </Button>
              </div>
            </>
          )}

          <p
            css={css`
              margin: 20px auto 0;
              text-align: center;
              max-width: 400px;
              color: #222;
              font-size: 12px;
            `}
          >
            By proceeding, you are accepting our{" "}
            <a
              target="_blank"
              href="https://useo2o.com/ca/terms"
              className="hover red"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              href="https://useo2o.com/ca/privacy"
              className="hover red"
            >
              Privacy Policy
            </a>
            , and agree to receive transactional and marketing emails from O2O.
            You may opt out at any time.
          </p>
        </div>
      </div>
    </>
  )
}

export default Onboarding
