/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useRef, useState } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import DownloadTable from "../../components/Table/MyListing/AssetsTable"
import {
  deleteMySingleForm,
  getBrandSingle,
  getMyListing,
  getMyListingPagination,
  getMySingleForm,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import AssetsTable from "../../components/Table/MyListing/AssetsTable"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import Modal from "../../components/Modal/Modal"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import Button from "../../components/Elements/Button"
import ProductsTable from "../../components/Table/MyListing/ProductsTable"
import NotificationContext from "../../context/Notification/NotificationContext"
import TableFilter from "../../components/Table/TableFilter"
import Skeleton from "../../components/Skeleton"
import { parse } from "query-string"
import Pagination from "../../components/Pagination"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import AccessDenied from "../../components/AccessDenied"
function MyAssets({ location }) {
  const { userType } = useContext(AuthenticationContext)

  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [deleteCurrentId, setDeleteCurrentId] = useState(null)
  const [formData, setFormData] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [hasMore, setHasMore] = useState(false)
  useEffect(async () => {
    let total = await getMyListingPagination(state.token, "product", "default")
    setTotal(total)

    let pages = Math.ceil(total / perPage)
    setTotalPage(pages)
  }, [])

  useEffect(() => {
    let pages = Math.ceil(total / perPage)
    setTotalPage(pages)
  }, [perPage])
  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  // }, [total])
  const onPageChange = async v => {
    setPageNumber(v)
    const newPageData = await getMyListing(state.token, "product", "default", v)
    // console.log(newPageData, "pageBody")
    setList([...newPageData])
    // setTotal(newPageData.total)
  }

  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = id => {
    setDeleteCurrentId(id)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrentId(null)
    setOpen(false)
  }
  const handleDelete = async id => {
    const res = await deleteMySingleForm(state.token, "product", "default", id)
    if (res.ok) {
      toggleNotification({ content: id + " Deleted Sucessfully" })
      const data = await getMyListing(state.token, "product")

      setList(data)
    } else {
      toggleNotification({
        content: id + " Failed to delete ",
        error: true,
      })
    }
  }
  const { toggleNotification } = useContext(NotificationContext)
  const onSave = async (data, v) => {
    if (v === "added") {
      const datas = await getMyListing(state.token, "product")
      // console.log(datas)
      setList([...datas])

      setOpenCreate(false)

      toggleNotification({ content: "product Added Sucessfully" })
    } else if (v === "edited") {
      const datas = await getMyListing(state.token, "product")
      // console.log(datas)
      setList([...datas])

      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: " product Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed" })
    }
  }
  console.log(location)
  useEffect(async () => {
    // console.log(state)
    if (location.search) {
      let { p } = parse(location.search)
      // console.log(p, parse(location.search))
      if (p) onPageChange(parseInt(p))
    } else {
      const data = await getMyListing(state.token, "product")
      // console.log(data)
      setList(data)
    }
    if (location?.state?.product_id) {
      console.log(location.state)
      onEditClick(location?.state?.product_id)
    }
    let formData = await getMySingleForm(state.token, "product")
    // console.log(formData)
    setFormData(formData)
  }, [])
  const [openCreate, setOpenCreate] = useState(false)
  const [showPagination, setShowPagination] = useState(true)
  const loadRef = useRef()

  if (!userType.brandManager) return <AccessDenied />

  return (
    <LayoutDashborad heading={"My Products"} trail={dashboardTrail}>
      <div className="mb-[30px] bg-blue-500 ">
        <Button red widthAuto onClick={() => setOpenCreate(true)}>
          Create New Product
        </Button>
      </div>

      <TableFilter
        onChange={setList}
        list={list}
        setShowPagination={setShowPagination}
        name="product"
        bundle={"default"}
        setHasMore={setHasMore}
        loadRef={loadRef}
      />

      {list === null ? (
        <Skeleton table />
      ) : list.length > 0 ? (
        <ProductsTable
          data={list}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      ) : (
        "No items to display"
      )}
      <div ref={loadRef} />
      {showPagination && (
        <Pagination
          page={pageNumber}
          setPage={v => onPageChange(v)}
          totalPages={totalPage}
        />
      )}
      {!showPagination && hasMore ? (
        <div className="w-full overflow-hidden flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
        </div>
      ) : (
        <p className="text-reg-14">No more results to display</p>
      )}
      <Modal
        title={"Create New Product"}
        isOpen={openCreate}
        closeModal={() => {
          setOpenCreate(false)
        }}
      >
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="product"
            bundle="default"
          />
        )}
      </Modal>
      {/* <SectionCard title={"Create New Products"} id="create">
        {formData !== null && (
          <DynamicForm
            onSave={onSave}
            addNew={true}
            data={formData}
            token={state.token}
            name="product"
            bundle="default"
          />
        )}
      </SectionCard> */}
      {open && editCurrent && (
        <Modal
          isOpen={open}
          closeModal={() => {
            setEditCurrent(null)
            setOpen(false)
          }}
          title={"Edit Product"}
        >
          <EditFormTemplate
            edit
            id={editCurrent}
            token={state.token}
            bundle="default"
            name={"product"}
            list={list}
            onSave={onSave}
          />
        </Modal>
      )}
      {open && deleteCurrentId && (
        <Modal
          isOpen={open}
          closeModal={closeDeleteModal}
          title={"Delete product"}
        >
          <DeleteConfirm
            onDelete={() => {
              handleDelete(deleteCurrentId).then(closeDeleteModal)
            }}
            onCancel={closeDeleteModal}
          />
        </Modal>
      )}
      {openAfterSave && (
        <Modal
          isOpen={openAfterSave}
          closeModal={() => {
            setOpenAfterSave(false)
          }}
        >
          <h2>{contentAfterSave}</h2>
        </Modal>
      )}
    </LayoutDashborad>
  )
}

export default MyAssets
