/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment, useContext, useEffect, useRef, useState } from "react"
import { verifiedHost } from "../../helpers/HostHelper"
import DataContext from "../../context/Data/DataContext"
// import FilterCheckbox from "../Elements/FilterCheckBox"
import FloatingInput from "../Elements/Form/FloatingInput"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import SearchIcon from "../../assets/icons/search.svg"
import { useFieldArray, useForm } from "react-hook-form"
// import {
//   entitySuggest,
//   getMyListingPost,
//   myEntitySuggestions,
// } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import FilterSelect from "../Elements/FilterSelect"
// import { toCapitalCase } from "../../utils"
// import ReportingContext from "../../context/Reporting/ReportingContext"
// import EntityReferenceRevisions from "../Forms/DynamicForm/FormTypes/EntityReferenceRevisions"
import fetch from "node-fetch"
const baseUrl = verifiedHost

function TableFilter({
  name,
  onChange,
  list,
  bundle,
  loadRef,
  setShowPagination,
  setHasMore: setLoader,
  seLoader: setLoading,
  setBody,
  setPageNumber,
}) {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()
  const [timer, setTimer] = useState(null)

  const abortControllerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const { token } = useContext(StateContext).state
  // const [searchValue, setSearchValue] = useState("")
  const { myBrandsAsOptions } = useContext(DataContext).data
  const { control, setValue, register, getValues } = useForm()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "brands",
    }
  )
  // const {
  //   company,
  //   segments,
  // } = useContext(ReportingContext)
  const { data } = useContext(DataContext)

  // console.log(data, "tagData")

  let datas = {
    title: "",
    brand: null,
    status: "all",
    tags: null,
    categories: null,
    type: "all",
  }
  const [bodyy, setData] = useState(datas)
  useEffect(() => {
    register("brand")
  }, [])

  const onChangeForm = async (type, v, signal = null) => {
    console.log(v)
    setLoading(true)
    let body = datas

    body = {
      ...bodyy,
      page: 1,
      [type]: v,
    }

    try {
      let response = await fetch(
        `${baseUrl}/api/my-listing/${name}/${bundle}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
          body: JSON.stringify(body),
          signal,
        },

      )
      let data = await response.json()

      if (data.length >= 20) {
      } else {
      }
      setData(body)
      setLoading(false)
      onChange(data)
      setBody(body)
      setPageNumber(1)

    }
    catch (error) {
      console.log(error)
    }

  }

  // const [term, setTerm] = useState("")

  const onChangeSearch = (value) => {

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    const newAbortController = new AbortController();

    abortControllerRef.current = newAbortController;

    onChangeForm("title", value, newAbortController.signal)

  };

  return (
    <div
      className={` ${false && `sticky`} top-[-1px] z-[1] ${false && isSticky === true
        ? "md:pt-[90px] transition-all"
        : "transition-none"
        }`}
      ref={ref}
    >
      <div
        className={`border p-[15px] border-[#EBEBEB] rounded-[8px] mb-[15px] bg-white ${false && isSticky === true && `!rounded-none`
          }`}
      >
        <div className="flex flex-col gap-4">
          <div className="grid items-center grid-cols-4 gap-4 max-lg:grid-cols-2 max-sm:grid-cols-1">
            <div className="">
              <FloatingInput
                label="Search For Anything"
                icon={<SearchIcon />}
                onChange={v => onChangeSearch(v.target.value)}
                className="!mb-0"
                name="title"
              />
            </div>
            <div className="">
              <FloatingSelect
                label="Category"
                options={data.categoriesAsOptions}
                onChange={v =>
                  onChangeForm(
                    "categories",
                    v?.length ? v.map(c => c.value) : null
                  )
                }
                isMulti={true}
                className="!mb-0"
              />
            </div>
            <div className="">
              <FloatingSelect
                label="Tag"
                options={data.tagsAsOptions}
                onChange={v =>
                  onChangeForm("tags", v?.length ? v.map(c => c.value) : null)
                }
                isMulti={true}
                className="!mb-0"
              />
            </div>
            {
            }
            {
            }

            <div className="">
              <FloatingSelect
                label="Filter by Brand"
                options={myBrandsAsOptions}
                onChange={v =>
                  onChangeForm("brand", v?.length ? v.map(c => c.value) : null)
                }
                isMulti={true}
                type="myBrandsAsOptions"
                className="!mb-0"
              />
            </div>
          </div>
          <div className="grid items-center grid-cols-6 gap-4 max-lg:grid-cols-3 max-sm:grid-cols-2 max-[400px]:grid-cols-1">
            {
            }
            {
            }
            {
            }
            <div className="text-center md:text-left">
              <FilterSelect
                options={[
                  { value: "all", label: "All" },
                  { value: "active", label: "Active" },
                  { value: "inactive", label: "Inactive" },
                ]}
                label="All Statuses"
                onChange={v => onChangeForm("status", v ? v : null)}
                className={"!border-0"}
                fromRight
              />
            </div>
            <div className="text-center md:text-left">
              <FilterSelect
                options={[
                  { value: "all", label: "All" },
                  { value: "digital", label: "Digital" },
                  { value: "physical", label: "Physical" },
                ]}
                label="All Types"
                onChange={v => onChangeForm("type", v ? v : null)}
                className={"!border-0"}
                fromRight
              />
            </div>
            {
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableFilter
