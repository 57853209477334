import React, {
  useReducer,
  useEffect,
  useContext,
  useState,
  useMemo,
} from "react"
import { navigate } from "gatsby"
import ls from "@livesession/sdk"
import { getUser, sessToken } from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import AuthenticationContext from "./AuthenticationContext"
import { useLocation } from "@reach/router"
import { isSSR } from "../../helpers/RendererHelper"
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  ChatAlt2Icon,
  MenuAlt2Icon,
  PhotographIcon,
  PuzzleIcon,
  UsersIcon,
  XIcon,
  UserGroupIcon,
  ShoppingBagIcon,
  OfficeBuildingIcon,
  BookOpenIcon,
  LogoutIcon,
} from "@heroicons/react/outline"
import Helmet from "react-helmet"
import Navigate from "../../assets/icons/navigate.svg"
import Download from "../../assets/icons/download-alt.svg"
import Warehouse from "../../assets/icons/warehouse-alt.svg"
import UserIcon from "../../assets/icons/user.svg"
import Settings from "../../assets/icons/settings.svg"
import Sent from "../../assets/icons/sent.svg"
import Shop from "../../assets/icons/shop.svg"
import Rss from "../../assets/icons/rss.svg"
import Folder from "../../assets/icons/folder.svg"
import Coupon from "../../assets/icons/coupon-alt.svg"
import Pin from "../../assets/icons/pin.svg"
import Grid from "../../assets/icons/grid.svg"
import Cart from "../../assets/icons/cart.svg"
// import { scriptStonlyIdentify } from "../../helpers/ScriptHelper"
import { isDev } from "../../helpers/HostHelper"
import AccountStatus from "../../pages/account-status"
import Register from "../../pages/register"
let allMenuItems = {
  my_assets: {
    name: "My Assets",
    href: "/dashboard/my-assets",
    icon: Cart,
    current: false,
  },
  my_products: {
    name: "My Products",
    href: "/dashboard/my-products",
    icon: Coupon,
    current: false,
  },
  my_account: {
    name: "My Account",
    href: "/dashboard/my-account",
    icon: UserIcon,
    current: true,
  },
  my_organization: {
    name: "Manage Stores",
    href: "/dashboard/my-organization",
    icon: OfficeBuildingIcon,
    stroke: true,
    current: false,
  },
  manage_files: {
    name: "Manage Files",
    href: "/dashboard/manage-files",
    icon: Folder,
    current: false,
  },
  // inventory: {
  //   name: "Inventory",
  //   href: "/dashboard/inventory",
  //   icon: Warehouse,
  //   current: false,
  // },
  inventory_submissions: {
    name: "Inventory Submissions",
    href: "/dashboard/inventory-submissions",
    icon: Warehouse,
    current: false,
  },
  reporting: {
    name: "Reporting",
    href: "/dashboard/reports",
    icon: ChartBarIcon,
    stroke: true,
    current: false,
  },
  manage_users: {
    name: "Manage Users",
    href: "/dashboard/manage-users",
    icon: UserIcon,
    current: false,
  },
  user_segmentation: {
    name: "User Segmentation",
    href: "/dashboard/user-segmentation",
    icon: Settings,
    current: false,
  },
  my_orders: {
    name: "My Orders",
    href: "/dashboard/my-orders",
    icon: Sent,
    current: false,
  },
  manage_subscriptions: {
    name: "Manage Notifications",
    href: "/dashboard/manage-subscriptions",
    icon: Rss,
    current: false,
  },
  address_book: {
    name: "Address Book",
    href: "/dashboard/address-book",
    icon: BookOpenIcon,
    current: false,
    stroke: true,
  },
  my_address: {
    name: "My Addresses",
    href: "/dashboard/address-book",
    icon: BookOpenIcon,
    current: false,
    stroke: true,
  },
  audit: {
    name: "Audit Log",
    href: "/dashboard/audit-log",
    icon: BookOpenIcon,
    current: false,
    stroke: true,
  },
  
  // my_media: {
  //   name: "Manage Media Files",
  //   href: "/dashboard/manage-files",
  //   icon: FolderIcon,
  //   current: false,
  //   stroke: true,
  // },
  company_status: {
    name: "Company Status",
    href: "/company-status",
    current: false,
  },
}
// let brandsPaths = [
//   {
//     name: "My Assets",
//     href: "/dashboard/my-assets",
//     icon: Cart,
//     current: false,
//   },
//
//   {
//     name: "My Products",
//     href: "/dashboard/my-products",
//     icon: Coupon,
//     current: false,
//   },
//   {
//     name: "Manage Files",
//     href: "/dashboard/manage-files",
//     icon: Folder,
//     current: false,
//   },
//   {
//     name: "User Segmentation",
//     href: "/dashboard/user-segmentation",
//     icon: Settings,
//     current: false,
//   },
//   {
//     name: "Inventory",
//     href: "/dashboard/inventory",
//     icon: Warehouse,
//     current: false,
//   },
//   {
//     name: "Reporting",
//     href: "/dashboard/reports",
//     icon: ChartBarIcon,
//     stroke: true,
//     current: false,
//   },
// ]
// let retailerPaths = [
//   {
//     name: "My Orders",
//     href: "/dashboard/my-orders",
//     icon: Sent,
//     current: false,
//   },
// ]

// export const navigationsStart = [
//   {
//     name: "My Account",
//     href: "/dashboard/my-account",
//     icon: UserIcon,
//     current: true,
//   },
//   {
//     name: "My Organization",
//     href: "/dashboard/my-organization",
//     icon: OfficeBuildingIcon,
//     stroke: true,
//     current: false,
//   },
// ]
// const brandNavigation = [
//   {
//     name: "My Account",
//     href: "/dashboard/my-account",
//     icon: UserIcon,
//     current: true,
//   },
//   {
//     name: "Manage Users",
//     href: "/dashboard/manage-users",
//     icon: UserIcon,
//     current: false,
//   },
// ]
//
// const retailerNavigation = [
//   {
//     name: "My Account",
//     href: "/dashboard/my-account",
//     icon: UserIcon,
//     current: true,
//   },
//   {
//     name: "Manage Organization",
//     href: "/dashboard/my-organization",
//     icon: UserIcon,
//     current: true,
//   },
//   {
//     name: "Manage Users",
//     href: "/dashboard/manage-users",
//     icon: UserIcon,
//     current: false,
//   },
// ]

// export const navigationsEnd = [
//   {
//     name: "Manage Users",
//     href: "/dashboard/manage-users",
//     icon: UserIcon,
//     current: false,
//   },
//   {
//     name: "Manage Subscriptions",
//     href: "/dashboard/manage-subscriptions",
//     icon: Rss,
//     current: false,
//   },
// ]

// let brandNavigation = [
//   "my_account",
//   "address_book",
//   "manage_users",
//   "user_segmentation",
//   "manage_subscriptions",
//   "reporting",
// ]
// let retailerNavigation = [
//   "my_account",
//   "address_book",
//   "my_organization",
//   "manage_users",
//   "user_segmentation",
//   "manage_subscriptions",
// ]
// let adminNavigation = [
//   "reporting",
// ] /* Just add, it'll default to 1 of the 2 arrays above, this will add in addition if not exists. */
// brandNavigation = brandNavigation.map(i => allMenuItems[i])
// retailerNavigation = retailerNavigation.map(i => allMenuItems[i])
// adminNavigation = adminNavigation.map(i => allMenuItems[i])

const initialState = {
  isAuthenticated: false,
  currentUserData: null,
}
const initialUserType = {
  brandEditor: false,
  brandManager: false,
  retailerEditor: false,
  retailerManager: false,
}
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,

        isAuthenticated: action.payload.isAuthenticated,
        currentUserData: action.payload,
      }

    case "LOGOUT":
      localStorage.clear()
      sessionStorage.clear()
      return initialState

    default:
      return state
  }
}
const LogOutItem = {
  name: "Logout",
  logOut: true,
  icon: LogoutIcon,
  stroke: true,
  current: false,
}
const AuthenticationProvider = ({ children }) => {
  // if(true) return <Maintenance />

  const [state, dispatch] = useReducer(reducer, initialState)
  const [loading, setLoading] = useState(true)
  const [cid, setCid] = useState("")
  const [navigation, setNavigation] = useState([])
  const [emptyAddressBook, setEmptyAddressBook] = useState(false)
  // const [navigation, setNavigation] = useState([
  //   ...navigationsStart,
  //   ...navigationsEnd,
  // ])
  // const [searchOptions, setSearchOptions] = useState({
  //   searchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  //   indexName: "main",
  // })

  const [searchOptions, setSearchOptions] = useState({
    searchKey: isDev
      ? process.env.GATSBY_ALGOLIA_SEARCH_KEY_DEV
      : process.env.GATSBY_ALGOLIA_SEARCH_KEY,
    indexName: isDev ? "dev" : "main",
  })

  const userType = useMemo(() => {
    if (
      state?.currentUserData?.companies?.find(item => item.type === "brand") &&
      state?.currentUserData?.companies[0]?.association === "lead"
    ) {
      return {
        ...initialUserType,
        brandManager: true,
      }
    }
    if (
      state?.currentUserData?.companies?.find(item => item.type === "brand") &&
      state?.currentUserData?.companies[0]?.association === "default"
    ) {
      return {
        ...initialUserType,
        brandEditor: true,
      }
    }
    if (
      state?.currentUserData?.companies?.find(
        item => item.type === "retailer"
      ) &&
      state?.currentUserData?.companies[0]?.association === "lead"
    ) {
      return {
        ...initialUserType,
        retailerManager: true,
      }
    }
    if (
      state?.currentUserData?.companies?.find(
        item => item.type === "retailer"
      ) &&
      state?.currentUserData?.companies[0]?.association === "default"
    ) {
      return {
        ...initialUserType,
        retailerEditor: true,
      }
    }
    return initialUserType
  }, [state])

  const { state: mainState, dispatch: dispatchToken } = useContext(StateContext)
  const location = useLocation()
  const [script, setScript] = useState(null)
  const showPage = [
    "/login",
    "/register",
    "/login/",
    "/register/",
    "/forgot-password",
    "/forgot-password/",
    "/new-password",
    "/new-password/",
    "/reset-password",
    "/reset-password/",
  ]

  let noCompanyNavigation = ["company_status"]
  let brandNavigation = ["my_account", "address_book"]
  let brandManagerNavigation = [
    "my_account",
    "my_address",
    "manage_users",
    "manage_subscriptions",
    "audit",
    // "my_media",
    "user_segmentation",
    "inventory_submissions",
  ]
  let retailerNavigation = ["my_account", "my_organization"]
  let retailerManagerNavigation = [
    "my_account",
    "manage_users",
    "my_organization",
    "manage_subscriptions",
  ]
  // let adminNavigation = [
  //   "manage_users",
  //   "user_segmentation",
  //   "reporting",
  // ] /* Just add, it'll default to 1 of the 2 arrays above, this will add in addition if not exists. */
  brandNavigation = brandNavigation.map(i => allMenuItems[i])
  if (userType.brandManager === true) {
    brandNavigation = []
    brandNavigation.push(...brandManagerNavigation.map(i => allMenuItems[i]))
  }
  retailerNavigation = retailerNavigation.map(i => allMenuItems[i])
  if (userType.retailerManager === true) {
    retailerNavigation = []
    retailerNavigation.push(
      ...retailerManagerNavigation.map(i => allMenuItems[i])
    )
  }
  // adminNavigation = adminNavigation.map(i => allMenuItems[i])
  // console.log({ retailerNavigation })
  const run = async () => {
    let token = state.token
    if (!token) {
      token = await sessToken()
      dispatchToken({ type: "SET_TOKEN", payload: token })
    }
    await getUser(token).then(async data => {
      // console.log(data)
      if (data.isAuthenticated === true) {
        // const s = scriptStonlyIdentify(data.i, data.onboarding_completed)
        // setScript(s)

        dispatch({
          type: "LOGIN",
          payload: data,
        })
        // if (data.status !== "approved") {
        // navigate("/account-status")
        // } else if (data.onboarding === 0 && data.retailers.length > 0) {
        //   navigate("/onboarding")
        // }
        if (!data.companies.length && data.account_completion !== "partial") {
          setNavigation(noCompanyNavigation)
          navigate("/company-status")
        } else if (location.pathname.includes("/login")) {
          navigate("/")
        }
        sessionStorage.setItem("user", JSON.stringify(data))
        // let newNavi = [...navigationsStart]

        if (data.brands.length) {
          // newNavi = [...newNavi, ...brandsPaths]
          setNavigation(brandNavigation)
        }
        if (data.retailers.length) {
          // newNavi = [...newNavi, ...retailerPaths]
          setNavigation(retailerNavigation)
        }
        // if (data._r.includes("administrator")) {
        //   setNavigation(prevState => [...prevState, ...adminNavigation])
        // }
        // newNavi = [...newNavi, ...navigationsEnd]
        setCid(data.i)
        // setSearchOptions({ searchKey: data.al_key, indexName: "main" })
        setSearchOptions({
          searchKey: data.al_key,
          indexName: isDev ? "dev" : "main",
        })

        // await getUserAddressBookListing(token).then(res => setUserAddresses(res))

        setLoading(false)
      } else {
        dispatch({
          type: "LOGIN",
          payload: data,
        })
        if (!showPage.includes(location.pathname)) {
          navigate("/login")
        }
        setLoading(false)
      }
    })
  }

  useEffect(async () => {
    await run()
  }, [state.isAuthenticated])

  useEffect(() => {
    if (!isSSR && !isDev) {
      const trackingId = "94030a3a.9740f92a"
      ls.init(trackingId)
      ls.newPageView()
    }
  }, [])
  useEffect(() => {
    if (!isSSR && !isDev) {
      if (state.currentUserData !== null) {
        setTimeout(() => {
          ls.identify({
            name:
              state.currentUserData.firstname +
              " " +
              state.currentUserData.lastname,
            email: state.currentUserData.email,
          })
        }, [10000])
      }
    }
  }, [state.currentUserData])

  if (state?.currentUserData?.account_completion === "partial")
    return (
      <AuthenticationContext.Provider
        value={{
          authentication: state,
          userType,
          currentUserId: cid,
          dispatchAuthentication: dispatch,
          navigation,
          searchOptions,
          setSearchOptions,
          runLogin: run,
        }}
      >
        <Register partialRegistration={true} />
      </AuthenticationContext.Provider>
    )

  if (state?.currentUserData?.status === "pending")
    return (
      <AuthenticationContext.Provider
        value={{
          authentication: state,
          userType,
          currentUserId: cid,
          dispatchAuthentication: dispatch,
          navigation,
          searchOptions,
          setSearchOptions,
          runLogin: run,
        }}
      >
        <AccountStatus />
      </AuthenticationContext.Provider>
    )

  return (
    <AuthenticationContext.Provider
      value={{
        authentication: state,
        userType,
        currentUserId: cid,
        dispatchAuthentication: dispatch,
        navigation,
        searchOptions,
        setSearchOptions,
        runLogin: run,
      }}
    >
      {loading ||
      (state.currentUserData?.isAuthenticated === true &&
        navigation.length === 0) ||
      state.currentUserData === null ? (
        // || (state.currentUserData !== null &&
        //   state.currentUserData.onboarding === 0 &&
        //   state.currentUserData.retailers.length > 0 &&
        //   !location.pathname.includes("/onboarding")
        //   )
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
        </div>
      ) : state.currentUserData.isAuthenticated === false &&
        !showPage.includes(location.pathname) ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
        </div>
      ) : (
        <>
          {true && script !== null && (
            <Helmet>
              <script dangerouslySetInnerHTML={{ _html: script }} />
            </Helmet>
          )}

          {children}
        </>
      )}
    </AuthenticationContext.Provider>
  )
}
export default AuthenticationProvider
