import GlobalProvider from "./src/context/GlobalProvider"
import "./src/styles/global.css"
import React from 'react';


export const wrapPageElement = ({ element, props }) => (
  <GlobalProvider {...props}>{element}</GlobalProvider>
)
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  return prevRouterProps?.location?.pathname !== routerProps.location.pathname
}
