/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import Textarea from "../Elements/Textarea"
import FloatingTextarea from "../Elements/Form/FloatingTextarea"
import FloatingInput from "../Elements/Form/FloatingInput"
import CartContext from "../../context/Cart/CartContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import Separator from "../Elements/Separator"

const ShippingOptions = ({
  override,
  name,
  setParentShippingState,
  parentShippingState,
  setOrderState,
  orderState,
  parentIndex,
  parentId,
  setParentId,
  structuredCart,
  setItemShippingState,
  setOrderNotes,
  idx,
  address_id,
  setGiftEmail,
  notifyEmail,
}) => {
  console.log(notifyEmail)
  const { userType } = useContext(AuthenticationContext)
  const [shippingState, setShippingState] = useState(parentShippingState)
  const [showEmailField, setShowEmailField] = useState(false)
  const shippingOptions = [
    {
      id: "standardShipping",
      value: "standard",
      label: (
        <p>
          <strong>{`Standard ${
            override ? "Shipping All Orders" : "Shipping"
          }`}</strong>{" "}
          (3-10 business days)
        </p>
      ),
    },
    {
      id: "expressShipping",
      value: "express",
      label: (
        <p>
          <strong>{`Express ${
            override ? "Shipping All Orders" : "Shipping"
          }`}</strong>{" "}
          (1-2 business days)
        </p>
      ),
    },
    {
      id: "selfPickup",
      value: "selfPickup",
      label: (
        <p>
          <strong>{`Self-Pickup ${override ? "All Orders" : ""}`}</strong>{" "}
          (Contact us)
        </p>
      ),
    },
  ]
  const extraOptions = [
    {
      id: "rushOrder",
      value: "rushOrder",
      label: (
        <p>
          <strong>{`Rush ${override ? "All Orders**" : "Order**"}`}</strong> (
          {`Push ${
            override ? "all orders" : "order"
          } to the very top of the warehouse pick & pack lists`}
          )
        </p>
      ),
    },
    {
      id: "giftOrder",
      value: "giftOrder",
      label: (
        <p>
          <strong>{`Gift ${override ? "All Orders" : "Order"}`}</strong> (
          {`Notify ${override ? " users at" : ""} this store that
          an order is on it’s way to them`}
          )
        </p>
      ),
    },
  ]
  const { userAddresses } = useContext(CartContext)
  const updatedExtraOptions = extraOptions.map(option => {
    const found = orderState?.find(item => item.id === option.id)
    if (found) {
      return {
        ...option,
        parentId: parentIndex,
        allChecked: found.allChecked,
      }
    }
    return option
  })

  // console.log(updatedExtraOptions)

  // console.log(rushOrderState)
  useEffect(() => {
    if (!override) {
      const selectedOption = shippingOptions.find(
        option => option.value === parentShippingState
      )
      if (selectedOption) {
        setShippingState(parentShippingState)
      } else {
        setShippingState("")
      }
    }
  }, [parentShippingState, override])
  // console.log(shippingState, "shippingState")

  const handleShippingChange = e => {
    setShippingState(e.target.value)
    if (override) {
      setParentShippingState(e.target.value)
    }
  }

  const extraOptionsChange = (e, optionName, index) => {
    if (
      parentIndex !== undefined &&
      !parentId?.includes(`${optionName}-${index}-${parentIndex}`)
    ) {
      setParentId([...parentId, `${optionName}-${index}-${parentIndex}`])
      setOrderState(prevState => {
        const newState = [...prevState]
        newState[index].allChecked = false
        return newState
      })
    } else if (parentIndex !== undefined) {
      const parentFilter = parentId.filter(
        item => item !== `${optionName}-${index}-${parentIndex}`
      )
      setParentId(parentFilter)
      setOrderState(prevState => {
        const newState = [...prevState]
        newState[index].allChecked = false
        return newState
      })
    }
    if (parentIndex === undefined) {
      setOrderState(prevState => {
        const newState = [...prevState]
        newState[index].allChecked = e.target.checked
        return newState
      })
      if (optionName === "rushOrder") {
        const parentItems = Array.from(
          { length: structuredCart },
          (_, index) => index
        ).map(item => `${optionName}-${index}-${item}`)
        if (
          !parentItems.filter(item => parentId.some(i => i === item)).length !==
            structuredCart &&
          e.target.checked
        ) {
          parentItems.map(item => parentId.push(item))
        } else {
          const parentFilter = parentId.filter(
            item => !parentItems.includes(item)
          )

          setParentId(parentFilter)
        }
      } else if (optionName === "giftOrder") {
        const parentItems = Array.from(
          { length: structuredCart },
          (_, index) => index
        ).map(item => `${optionName}-${index}-${item}`)
        if (
          parentItems.filter(item => parentId.some(i => i === item)).length !==
            structuredCart &&
          e.target.checked
        ) {
          parentItems.map(item => parentId.push(item))
        } else {
          const parentFilter = parentId.filter(
            item => !parentItems.includes(item)
          )

          setParentId(parentFilter)
        }
      }
    }
  }

  const uniqueParentId = [...new Set(parentId)]
  const updateItemShippingState = (shippingState, parentIndex) => {
    setItemShippingState(prev => {
      // Check if an item with the same parentIndex exists
      const existingItemIndex = prev.findIndex(
        item => item.parentIndex === parentIndex
      )

      // If item exists, update its shippingState, otherwise, add a new item
      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...prev[existingItemIndex],
          shippingState: shippingState,
        }
        // Create a new array with updated item
        return [
          ...prev.slice(0, existingItemIndex),
          updatedItem,
          ...prev.slice(existingItemIndex + 1),
        ]
      } else {
        // Add new item
        return [...prev, { shippingState, parentIndex }]
      }
    })
  }

  useEffect(() => {
    if (!override) {
      updateItemShippingState(shippingState, parentIndex)
      // console.log({ shippingState, parentIndex })
    }
  }, [shippingState])

  return (
    <>
      {userType.brandEditor === true  || userType.brandManager === true && (
        <>
          <div className="flex flex-col gap-4">
            {shippingOptions.map((item, index) => {
              return (
                <div className="flex items-center" key={index}>
                  <input
                    type="radio"
                    defaultChecked={item.id === "standardShipping"}
                    id={item.id + idx}
                    name={name}
                    value={item.value}
                    className="appearance-none h-5 w-5 border border-[#595a5a] rounded-full checked:bg-[#595a5a] checked:border-transparent checked:ring-2 checked:ring-[#595a5a] focus:outline-none cursor-pointer hover:border-[#595a5a] focus:border-[#595a5a] text-[#595a5a]"
                    style={{ outline: "none !important" }}
                    onChange={handleShippingChange}
                    checked={shippingState === item.value}
                  />
                  <label htmlFor={item.id + idx} className="ml-4">
                    {item.label}
                  </label>
                </div>
              )
            })}
          </div>

          <div
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              display: inline-block;
              padding: 0;
              margin: 7px 0 20px 0;
              letter-spacing: 0.02em;
              color: #717171;
              margin-top: 20px;
            `}
          >
            <p>* Express will incur additional shipping carrier costs.</p>
            <p>** Arrange a pickup date at least 72 hours in advance.</p>
          </div>
          <div className="flex flex-col gap-4">
            {/* {console.log(updatedExtraOptions)} */}
            {updatedExtraOptions.map((item, index) => (
              <>
                <div className="flex items-center" key={index}>
                  <input
                    type="checkbox"
                    id={item.id + idx}
                    name={`shippingOption_${item.id + idx}`}
                    value={item.value}
                    className="appearance-none h-5 w-5 border border-[#595a5a] checked:bg-[#595a5a] checked:border-transparent checked:ring-2 checked:ring-[#595a5a] focus:outline-none cursor-pointer hover:border-[#595a5a] focus:border-[#595a5a] text-[#595a5a]"
                    style={{ outline: "none !important" }}
                    onChange={e => extraOptionsChange(e, `${item.id}`, index)}
                    checked={
                      item.allChecked
                        ? item.allChecked
                        : parentIndex !== undefined
                        ? parentId.includes(
                            `${item.id}-${index}-${parentIndex}`
                          )
                        : item.checked
                    }
                  />
                  <label htmlFor={item.id + idx} className="ml-4">
                    {item.label}
                  </label>
                </div>
                {index !== updatedExtraOptions.length - 1 && <Separator />}
              </>
            ))}
          </div>
          {
            //if gift order checkbox is true
            uniqueParentId.find(item => item.includes(`giftOrder-1-${idx}`)) !==
              undefined && (
              // Object.keys(specifyGiftNotificationEmail).includes(
              //   address_id
              // ) &&
              <div className="rounded-[8px] bg-[#FFF7E2] px-5 py-5 mt-4">
                <p className="text-reg-14 !text-[#a57c0b] !leading-[1.5] mb-2">
                  We'll notify the following user of the order:
                </p>
                <>
                  <div
                    // className="mt-"
                    css={css`
                      &&& {
                        label {
                          background-color: #fff7e2 !important;
                          top: 8px;
                          font-size: 13px;
                          color: #a57c0b;
                        }
                        input {
                          border-color: #a57c0b;
                          font-size: 13px;
                          padding-top: 10px !important;
                          padding-bottom: 10px !important;
                          line-height: 23px;
                          &:focus {
                            border-color: #a57c0b !important;
                            ~ label {
                              top: 14px;
                            }
                          }
                        }
                      }
                    `}
                  >
                    {notifyEmail && (
                      <div>
                        <p className="text-reg-14 !text-[#a57c0b] !leading-[1.5] mb-2">
                          {notifyEmail.res}
                        </p>
                        <p
                          onClick={() => {
                            setShowEmailField(!showEmailField)
                          }}
                          className="text-reg-14 !text-[#a57c0b] !leading-[1.5] mb-2 cursor-pointer inline-block hover:text-[#a57c0b] underline hover:no-underline"
                        >
                          Notify someone else?
                        </p>
                      </div>
                    )}
                    {showEmailField && (
                      <FloatingInput
                        label={"Email address"}
                        name={`address_${idx}`}
                        type={"email"}
                        className={"!mb-0"}
                        required={true}
                        onChange={e => {
                          // setSpecifyGiftNotificationEmail(prevState => ({
                          //   ...prevState,
                          //   [item]: e.target.value,
                          // }))
                          setGiftEmail(prevState => {
                            const newGiftEmail = [...prevState]
                            newGiftEmail[idx] = {
                              id: address_id,
                              email: e.target.value,
                            }
                            return newGiftEmail
                          })
                        }}
                      />
                    )}
                  </div>
                </>
              </div>
            )
          }

          <div
            css={css`
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              display: inline-block;
              padding: 0;
              margin: 7px 0 20px 0;
              letter-spacing: 0.02em;
              color: #717171;
              margin-top: 20px;
            `}
          >
            <p>**Rush will incur an additional $10 charge per order.</p>
          </div>
        </>
      )}
      {!override && (
        <div>
          <FloatingTextarea
            label="Order Note (Optional)"
            onChange={e => {
              setOrderNotes(prevOrderNotes => {
                const newOrderNotes = [...prevOrderNotes]
                newOrderNotes[idx] = e.target.value
                return newOrderNotes
              })
            }}
            description={
              userType.brandEditor === true
                ? "Include any notes here that you would either like our warehouse staff to see or any store staff to see if you are gifting this order."
                : "Include any notes here that you would like our warehouse staff to see."
            }
          />
        </div>
      )}
    </>
  )
}

export default ShippingOptions
